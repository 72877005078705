import React, { useState, useEffect, useContext } from "react";
import {
  getTrainerReportApi,
  getDistinct_test_API,
} from "../../../src/api/endpoints";
import "../../Styles/TrainingAdmin.css";
import back from "../../assets/Images/backarrow.png";

import Next from "../../assets/Images/nextarrow.png";
import { SearchContext } from "../../AllSearch/SearchContext";
import { Table, Form, Pagination } from "react-bootstrap";
import Download from "../../assets/Images/download.png";
import * as XLSX from "xlsx";

const FilterDropdown = ({ options, selectedValue, onChange, className }) => {
  return (
    <select
      value={selectedValue || ""}
      onChange={(e) => onChange(e.target.value)}
      className={`filter-dropdown ${className}`}
    >
      <option value="">All</option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

const TrainerFeedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [currentTrainerIndex, setCurrentTrainerIndex] = useState(0); // Track the index of the currently displayed trainer
  const [error, setError] = useState(null);
  const { searchQuery } = useContext(SearchContext);
  const [search, setSearch] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = feedback.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(feedback.length / itemsPerPage);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const [filtersSt, setFiltersSt] = useState({});

  const exportToExcel = () => {
    const filteredData = feedback.map(({ report_id, ...rest }) => rest); // Exclude id field
    const headerMap = {
      college_name: "CollegeName",
      department_name: "DepartmentName",
      year: "Year",
      topic: "Topic",
      subTopic: "SubTopic",
      Trainer_name: "TrainerName",
      dtm_session: "Date",
      Status: "Status",
      no_of_question_solved: "No.Of Questions",
      comments: "Comments",
      activities_done: "Activities Done",
      student_feedback: "Student Feedback",
      infrastructure_feedback: "Infrastructure Feedback",
    };

    const wsData = filteredData.map((candidate) => {
      const modifiedCandidate = {};
      for (let key in candidate) {
        if (headerMap[key]) {
          modifiedCandidate[headerMap[key]] = candidate[key];
        } else {
          modifiedCandidate[key] = candidate[key];
        }
      }
      return modifiedCandidate;
    });

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Feedback Report");
    XLSX.writeFile(wb, "feedback_report.xlsx");
  };

  const getPaginationItems = () => {
    const items = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage === 1) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage === totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  useEffect(() => {
    // Fetch trainer data when the component mounts
    fetchFeedback();
  }, [searchQuery, search, filtersSt]);

  const fetchFeedback = async () => {
    try {
      const response = await getTrainerReportApi();
      // Filter response based on filter criteria
      const filteredFeedback = response.filter(
        (item) =>
          (filtersSt.college_name
            ? item.college_name === filtersSt.college_name
            : true) && // Filter by college name
          (filtersSt.trainer_name
            ? item.trainer_name === filtersSt.trainer_name
            : true) &&
          (searchQuery
            ? (item.college_name?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.department_name?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.year?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.topic?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.sub_topic?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.trainer_name?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.dtm_of_training?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.status?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.student_feedback?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (item.infrastructure_feedback?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) ||
              (String(item.activities_done)?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              ) || // Convert to string
              (
                String(item.no_of_question_solved)?.toLowerCase() || ""
              ).includes(searchQuery.toLowerCase()) || // Convert to string
              (item.remarks?.toLowerCase() || "").includes(
                searchQuery.toLowerCase()
              )
            : true) &&
          (search
            ? (item.college_name?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.department_name?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.year?.toLowerCase() || "").includes(search.toLowerCase()) ||
              (item.topic?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.sub_topic?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.trainer_name?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.dtm_of_training?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.status?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.student_feedback?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (item.infrastructure_feedback?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) ||
              (String(item.activities_done)?.toLowerCase() || "").includes(
                search.toLowerCase()
              ) || // Convert to string
              (
                String(item.no_of_question_solved)?.toLowerCase() || ""
              ).includes(search.toLowerCase()) || // Convert to string
              (item.remarks?.toLowerCase() || "").includes(search.toLowerCase())
            : true)
      );
      setFeedback(filteredFeedback); // Set the filtered data to the state
      console.log("Feed Back: ", filteredFeedback);
    } catch (error) {
      setError("Failed to fetch trainer data");
      console.error(error);
    }
  };

  const filterOptions = {
    college_name: [
      ...new Set(feedback.map((candidate) => candidate.college_name || "")),
    ],
    trainer_name: [
      ...new Set(feedback.map((candidate) => candidate.trainer_name || "")),
    ],
  };

  const handleFilterChangeSt = (key, value) => {
    setFiltersSt((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  return (
    <div className="form-ques-stufeed">
      <h5>Trainers Report</h5>

      <input
        className="search-box-db-nondb"
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <button
        className="button-ques-save"
        onClick={exportToExcel}
        style={{ width: "100px", marginRight: "10px" }}
      >
        <img src={Download} className="nextarrow"></img>
        <span>Export</span>
      </button>

      <div className="table-responsive-trainers">
        <table className="product-table">
          <thead className="table-thead" style={{ textAlign: "center" }}>
            <tr className="header-row">
              <th className="title-place" style={{ textAlign: "center" }}>
                College Name
                <FilterDropdown
                  options={filterOptions.college_name || []}
                  selectedValue={filtersSt.college_name}
                  onChange={(value) =>
                    handleFilterChangeSt("college_name", value)
                  }
                  className="dropdown-filter-college"
                />
              </th>
              <th className="title-place" style={{ textAlign: "center" }}>
                Department
              </th>
              {/*}  <th className="title-place" style={{ textAlign: "center" }}>Year</th>   */}
              <th className="title-place" style={{ textAlign: "center" }}>
                Topic
              </th>
              <th className="title-place" style={{ textAlign: "center" }}>
                Sub Topic
              </th>
              <th className="title-place" style={{ textAlign: "center" }}>
                Trainer Name
                <FilterDropdown
                  options={filterOptions.trainer_name || []}
                  selectedValue={filtersSt.trainer_name}
                  onChange={(value) =>
                    handleFilterChangeSt("trainer_name", value)
                  }
                  className="dropdown-filter-trainer"
                />
              </th>
              <th className="title-place" style={{ textAlign: "center" }}>
                Session Date
              </th>
              <th className="title-place" style={{ textAlign: "center" }}>
                Status
              </th>
              {/*}  <th className="title-place" style={{ textAlign: "center" }}>Comments</th>
              <th className="title-place" style={{ textAlign: "center" }}>Students Feedback</th>
              <th className="title-place" style={{ textAlign: "center" }}>Infrastructure Feedback</th>
              <th className="title-place" style={{ textAlign: "center" }}>Activities Done</th>
              <th className="title-place" style={{ textAlign: "center" }}>No of Questions Solved</th>
              <th className="title-place" style={{ textAlign: "center" }}>Remarks</th>    */}
            </tr>
          </thead>
          <tbody className="table-tbody" style={{ fontSize: "16px" }}>
            {currentData.length > 0 ? (
              currentData.map((currentTrainer, index) => (
                <tr key={index} className="table-row">
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.college_name}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.department_name}
                  </td>
                  {/*}  <td style={{ textAlign: "center" }}>{currentTrainer.year}</td>  */}
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.topic}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.sub_topic}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.trainer_name}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.dtm_of_training}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {currentTrainer.status}
                  </td>
                  {/*}  <td style={{ textAlign: "center" }}>{currentTrainer.comments}</td>
                <td style={{ textAlign: "center" }}>{currentTrainer.student_feedback}</td>
                <td style={{ textAlign: "center" }}>{currentTrainer.infrastructure_feedback}</td>
                <td style={{ textAlign: "center" }}>{currentTrainer.activities_done}</td>
                <td style={{ textAlign: "center" }}>{currentTrainer.no_of_question_solved}</td>
                <td style={{ textAlign: "center" }}>{currentTrainer.remarks}</td>   */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={14} style={{ textAlign: "center" }}>
                  No feedback found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="dis-page">
        <Form>
          <Form.Group
            controlId="itemsPerPageSelect"
            style={{ display: "flex" }}
          >
            <Form.Label style={{ marginRight: "10px" }}>Display:</Form.Label>
            <Form.Control
              className="label-dis"
              style={{ width: "50px", boxShadow: "none", outline: "none" }}
              as="select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Form.Control>
          </Form.Group>
        </Form>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {getPaginationItems()}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default TrainerFeedback;
