import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import menuIcon from '../assets/Images/menu.png';
import DashboardIcon from '../assets/Images/dashboard.png';
import DatabaseIcon from '../assets/Images/Database.png';
import TestIcon from '../assets/Images/Test.png';
import PracticesIcon from '../assets/Images/practice.png';
import LMSIcon from '../assets/Images/lms.png';
import CompanyIcon from '../assets/Images/company-statestic.png';
import announcement from '../assets/Images/annonucement.png';
import Cube from '../assets/Images/cube.png';
import Downarrow from '../assets/Images/dowm.png';
import questionsPng from '../assets/Images/questions.png';
// Update this import path to the correct one
import { TestTypeContext, TestTypeCategoriesContext, QuestionTypeContext, SkillTypeContext } from '../Components/Test/context/TestTypeContext';

import ProfileIcon from '../assets/Images/jobprofile.png';

const Sidebar = () => {
  const { setSelectedTestType } = useContext(TestTypeContext);
  const { setSelectedTestTypeCategory } = useContext(TestTypeCategoriesContext);
  const { setSelectedQuestionType } = useContext(QuestionTypeContext);
  const { setSelectedSkillType } = useContext(SkillTypeContext);

  const [isOpen, setIsOpen] = useState(true); // Changed initial state to true
  const [isTestOpen, setIsTestOpen] = useState(false);
  const [isDatabaseOpen, setIsDatabaseOpen] = useState(false);
  const [islmsOpen, setIslmsOpen] = useState(false);
  const [isdashopen, setIsDashOpen] = useState(false);
  const [isjobopen, setIsjobOpen] = useState(false);
  const [isinvoiceopen, setIsinvoiceOpen] = useState(false);

  const [isannouncementopen, setIsannouncementOpen] = useState(false);

  const [isQuestionsOpen, setIsQuestionsOpen] = useState(false);
  const [ispracticeOpen, setIspracticeOpen] = useState(false);
  const [iscmpyOpen, setIscmpyOpen] = useState(false);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isMCQOpen, setIsMCQOpen] = useState(false);
  const [selectedMCQOption, setSelectedMCQOption] = useState(null);
  const [isCodingOpen, setIsCodingOpen] = useState(false);
  const [selectedCodingOption, setSelectedCodinOption] = useState(null);

  const [isPreOpen, setIsPreOpen] = useState(false);
  const [selectedPreOption, setSelectedPreOption] = useState(null);
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [isAssessmentOpen, setIsAssessmentOpen] = useState(false);
  const [isMockOpen, setIsMockOpen] = useState(false);
  const [isComOpen, setIsComOpen] = useState(false);
  const [isPsyOpen, setIsPsyOpen] = useState(false);
  const [isSoftOpen, setIsSoftOpen] = useState(false);
  const [isPracOpen, setIsPracOpen] = useState(false);

  const [isSkillOpen, setIsSkillOpen] = useState(false);
  const [selectedSkillTypeOption, setSelectedSkillTypeOption] = useState(null);
  const [selectedSkillTypeOptionLMS, setSelectedSkillTypeOptionLMS] = useState(null);

  const [isAptitudeOpen, setIsAptitudeOpen] = useState(false);
  const [isSfOpen, setIsSfOpen] = useState(false);
  const [isTechOpen, setIsTechOpen] = useState(false);

  const [isAptitudeOpenLMS, setIsAptitudeOpenLMS] = useState(false);
  const [isSfOpenLMS, setIsSfOpenLMS] = useState(false);
  const [isTechOpenLMS, setIsTechOpenLMS] = useState(false);
  const [isTechOpenLMSmap, setIsTechOpenLMSmap] = useState(false);

  const [isTechOpenCode, setIsTechOpenCode] = useState(false);
  const [isPreOpenCode, setIsPreOpenCode] = useState(false);
  const [isPostOpenCode, setIsPostOpenCode] = useState(false);
  const [isAssessmentOpenCode, setIsAssessmentOpenCode] = useState(false);
  const [isMockOpenCode, setIsMockOpenCode] = useState(false);
  const [isComOpenCode, setIsComOpenCode] = useState(false);
  const [isPracOpenCode, setIsPracOpenCode] = useState(false);


  const [selectedTestTypeOption, setSelectedTestTypeOption] = useState(null);
  const [isTestaccessVisible, setIsTestaccessVisible] = useState(false); // Set to false to hide initially



  const [activeMenuItem, setActiveMenuItem] = useState(null);

  //const [isCollapsed, setIsCollapsed] = useState(false);

  const handleMenuItemClick = (menuItem, isLastSubMenu) => {
    setActiveMenuItem(menuItem);

    // setIsOpen(false); // Expand the sidebar if it's collapsed

  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleSubmenu = () => {

    setIsOpen(false);
  };
  const toggledashMenu = () => {
    setIsDashOpen(!isdashopen && true)
    setIslmsOpen(false);
    setIsTestOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsAptitudeOpenLMS(false);
    setIsTechOpenLMS(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };

  const togglejobMenu = () => {
    setIsjobOpen(!isjobopen && true);
    setIsDashOpen(false);
    setIslmsOpen(false);
    setIsTestOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsAptitudeOpenLMS(false);
    setIsTechOpenLMS(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };

  const toggleinvoiceMenu = () => {
    setIsinvoiceOpen(!isinvoiceopen && true);
    setIsjobOpen(false);
    setIsDashOpen(false);
    setIslmsOpen(false);
    setIsTestOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsAptitudeOpenLMS(false);
    setIsTechOpenLMS(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };

  const toggleannounceMenu = () => {
    setIsannouncementOpen(isannouncementopen && true);
    setIslmsOpen(false);
    setIsDashOpen(false);
    setIsTestOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsAptitudeOpenLMS(false);
    setIsTechOpenLMS(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };



  const toggleReportsMenu = () => {
    setIslmsOpen(false);
    setIsTestOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsAptitudeOpenLMS(false);
    setIsTechOpenLMS(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };


  const toggleQuestionsMenu = () => {
    setIsQuestionsOpen(!isQuestionsOpen && true);
    setIslmsOpen(false);
    setIsTestOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsAptitudeOpenLMS(false);
    setIsTechOpenLMS(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };


  const toggleTestMenu = () => {
    setIsTestOpen(!isTestOpen && true);
    setIslmsOpen(false);
    setIsDatabaseOpen(false);
    setIscmpyOpen(false);
    setIsMapOpen(false);
    setIsTechOpenCode(false);
    setIsCodingOpen(false);
    setIsQuestionsOpen(false);

  };



  const toggleDatabaseMenu = () => {
    setIsDatabaseOpen(!isDatabaseOpen && true);
    setIsTestOpen(false);
    setIslmsOpen(false);
    setIscmpyOpen(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };



  const toggleMapTest = () => {
    setIsMapOpen(!isMapOpen && true);
    setIsMCQOpen(false);
    setIsCodingOpen(false);
    setIsQuestionsOpen(false);


  };

  const toggleMCQMenu = () => {
    setIsMCQOpen(!isMCQOpen && true);
    setIsCodingOpen(false);
    setIsPreOpen(false);
    setIsPostOpen(false);
    setIsPracOpen(false);
    setIsAssessmentOpen(false);
    setIsMockOpen(false);
    setIsComOpen(false);
    setIsPsyOpen(false);
    setIsQuestionsOpen(false);

    setSelectedTestType('MCQ Test');

  };


  const toggleCodingMenu = () => {
    setIsCodingOpen(!isCodingOpen && true);
    setIsMCQOpen(false);
    setIsPreOpenCode(false);
    setIsPostOpenCode(false);
    setIsPracOpenCode(false);
    setIsAssessmentOpenCode(false);
    setIsMockOpenCode(false);
    setIsComOpenCode(false);
    setIsQuestionsOpen(false);

    setSelectedTestType('Coding Test');

  };

  const handleMcqOp = (option) => {
    setSelectedMCQOption(option);
    setSelectedTestTypeCategory(option);
    console.log('Selected MCQ option: ', option)
  };

  const handleCodingOp = (option) => {
    setSelectedCodinOption(option);
    setSelectedTestTypeCategory(option);
    console.log('Selected Coding option: ', option)
  };


  const handleTestType = (option) => {
    setSelectedTestTypeOption(option);
    console.log('Selected Test Type option: ', option)
  };



  const togglePreMenu = () => {
    setIsPreOpen(!isPreOpen && true);
    setIsPostOpen(false);
    setIsPracOpen(false);
    setIsAssessmentOpen(false);
    setIsMockOpen(false);
    setIsComOpen(false);
    setIsPsyOpen(false);
    setIsAptitudeOpen(false);
    setIsTechOpen(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };


  const togglePreMenuCode = () => {
    setIsPreOpenCode(!isPreOpenCode && true);
    setIsPostOpenCode(false);
    setIsPracOpenCode(false);
    setIsAssessmentOpenCode(false);
    setIsMockOpenCode(false);
    setIsComOpenCode(false);
    setIsTechOpenCode(false);
    setIsQuestionsOpen(false);


  };


  const togglePostMenu = () => {
    setIsPostOpen(!isPostOpen && true);
    setIsPreOpen(false);
    setIsPracOpen(false);
    setIsAssessmentOpen(false);
    setIsMockOpen(false);
    setIsComOpen(false);
    setIsPsyOpen(false);
    setIsAptitudeOpen(false);
    setIsTechOpen(false);
    setIsQuestionsOpen(false);
    if (!isOpen) {
      setIsOpen(true); // Expand the sidebar if it's collapsed
    }
  };




  const toggleAssessMenu = () => {
    setIsAssessmentOpen(!isAssessmentOpen);
    setIsPreOpen(false);
    setIsPostOpen(false);
    setIsPracOpen(false);
    setIsMockOpen(false);
    setIsComOpen(false);
    setIsPsyOpen(false);
    setIsAptitudeOpen(false);
    setIsTechOpen(false);
    setIsQuestionsOpen(false);

  };

  const toggleAssessMenuCode = () => {
    setIsAssessmentOpenCode(!isAssessmentOpenCode);
    setIsPostOpenCode(false);
    setIsPracOpenCode(false);
    setIsPreOpenCode(false);
    setIsMockOpenCode(false);
    setIsComOpenCode(false);
    setIsTechOpenCode(false);
    setIsQuestionsOpen(false);

  };


  const toggleMockMenu = () => {
    setIsMockOpen(!isMockOpen && true);
    setIsPostOpen(false);
    setIsPracOpen(false);
    setIsAssessmentOpen(false);
    setIsPreOpen(false);
    setIsComOpen(false);
    setIsPsyOpen(false);
    setIsAptitudeOpen(false);
    setIsTechOpen(false);
    setIsQuestionsOpen(false);

  };

  const toggleMockMenuCode = () => {
    setIsMockOpenCode(!isMockOpenCode && true);
    setIsPostOpenCode(false);
    setIsPracOpenCode(false);
    setIsAssessmentOpenCode(false);
    setIsPreOpenCode(false);
    setIsComOpenCode(false);
    setIsTechOpenCode(false);
    setIsQuestionsOpen(false);

  };


  const toggleComMenu = () => {
    setIsComOpen(!isComOpen && true);
    setIsPostOpen(false);
    setIsPracOpen(false);
    setIsAssessmentOpen(false);
    setIsMockOpen(false);
    setIsPreOpen(false);
    setIsPsyOpen(false);
    setIsAptitudeOpen(false);
    setIsTechOpen(false);
    setIsQuestionsOpen(false);

  };

  const toggleComMenuCode = () => {
    setIsComOpenCode(!isComOpenCode && true);
    setIsPostOpenCode(false);
    setIsPracOpenCode(false);
    setIsAssessmentOpenCode(false);
    setIsMockOpenCode(false);
    setIsPreOpenCode(false);
    setIsTechOpenCode(false);
    setIsQuestionsOpen(false);

  };




  const toggleSoftMenu = () => {
    setIsSoftOpen(!isSoftOpen);
  };

  const togglePracMenu = () => {
    setIsPracOpen(!isPracOpen && true);
    setIsPostOpen(false);
    setIsAssessmentOpen(false);
    setIsMockOpen(false);
    setIsComOpen(false);
    setIsPreOpen(false);
    setIsAptitudeOpen(false);
    setIsTechOpen(false);
    setIsQuestionsOpen(false);
  };



  const handlePre = (option) => {
    setSelectedPreOption(option);
    console.log('Selected Pre option: ', option)
    setSelectedQuestionType(option);
  };


  const handleSkillType = (option) => {
    setSelectedSkillTypeOption(option);
    console.log('Selected skill option: ', option)
    setSelectedSkillType(option);

  };

  const handleSkillTypeLMS = (option) => {
    setSelectedSkillTypeOptionLMS(option);
    console.log('Selected skilltypelms option: ', option)
    setSelectedSkillType(option);

  };

  const toggleSkillMenu = () => {
    setIsSkillOpen(!isSkillOpen);
  };


  const toggleAptitudeMenu = () => {
    setIsAptitudeOpen(!isAptitudeOpen && true);
    setIsTechOpen(false);

  };


  const toggleSfMenu = () => {
    setIsSfOpen(!isSfOpen);
  };


  const toggleTechMenu = () => {
    setIsTechOpen(!isTechOpen && true);
    setIsAptitudeOpen(false);

  };


  const toggleAptitudeMenuLMS = () => {
    setIsAptitudeOpenLMS(!isAptitudeOpenLMS && true);
    setIsTechOpenLMS(false);

  };

  const toggleTechMenuLMS = () => {
    setIsTechOpenLMS(!isTechOpenLMS && true);
    setIsAptitudeOpenLMS(false);

  };
  const toggleTechMenuLMSmap = () => {
    setIsTechOpenLMSmap(!isTechOpenLMSmap && true);
    setIsAptitudeOpenLMS(false);

  };

  const toggleTechMenuCode = () => {
    setIsTechOpenCode(!isTechOpenCode && true);

  };



  const SkilltypeAptitude = () => (
    <ul className='test-options' style={{ paddingLeft: '15px' }}>
      <Link to='/test/test-access' onClick={() => handleSkillType('Quants')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'quants' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('quants'); toggleSubmenu(); }}>

          <span className="dashboard-text">Quants</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('Logical')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'logical' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('logical'); toggleSubmenu(); }}>

          <span className="dashboard-text">Logical</span>
        </li>
      </Link>
      <Link to='/test/test-access' onClick={() => handleSkillType('Verbal')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'verbal' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('verbal'); toggleSubmenu(); }}>

          <span className="dashboard-text">Verbal</span>
        </li>
      </Link>
    </ul>

  )

  const SkilltypeAptitudeLMS = () => (
    <ul className='test-options' style={{ paddingLeft: '25px' }}>
      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('Quants')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'quants' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('quants'); toggleSubmenu(); }}>

          <span className="dashboard-text">Quants</span>
        </li>
      </Link>
      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('Logical')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'logical' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('logical'); toggleSubmenu(); }}>

          <span className="dashboard-text">Logical</span>
        </li>
      </Link>
      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('Verbal')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'verbal' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('verbal'); toggleSubmenu(); }}>

          <span className="dashboard-text">Verbal</span>
        </li>
      </Link>

    </ul>

  )

  const SkilltypeTechnicalLMS = () => (
    <ul className='test-options' style={{ paddingLeft: '25px' }}>
      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('C')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'c' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('c'); toggleSubmenu(); }}>
          <span className="dashboard-text">C</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('C++')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'c++' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('c++'); toggleSubmenu(); }}>
          <span className="dashboard-text">C++</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('JAVA')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'java' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('java'); toggleSubmenu(); }}>
          <span className="dashboard-text">JAVA</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('Python')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'python' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('python'); toggleSubmenu(); }}>
          <span className="dashboard-text">Python</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('IOT')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'iot' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('iot'); toggleSubmenu(); }}>
          <span className="dashboard-text">IOT</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('ML')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'ml' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('ml'); toggleSubmenu(); }}>
          <span className="dashboard-text">ML</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('AI')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'ai' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('ai'); toggleSubmenu(); }}>
          <span className="dashboard-text">AI</span>
        </li>
      </Link>

      <Link to='/Lms/upload-video' onClick={() => handleSkillTypeLMS('Data Structures')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'datastructures' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('datastructures'); toggleSubmenu(); }}>
          <span className="dashboard-text">Data Structures</span>
        </li>
      </Link>
    </ul>
  );

  const SkilltypeTechnical = () => (
    <ul className='test-options' style={{ paddingLeft: '10px' }}>

      <Link to='/test/test-access' onClick={() => handleSkillType('All Languages')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'All Languages' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('All Languages'); toggleSubmenu(); }}>
          <span className="dashboard-text">All</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('C')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'c' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('c'); toggleSubmenu(); }}>
          <span className="dashboard-text">C</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('C++')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'c++' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('c++'); toggleSubmenu(); }}>
          <span className="dashboard-text">C++</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('JAVA')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'java' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('java'); toggleSubmenu(); }}>
          <span className="dashboard-text">JAVA</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('Python')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'python' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('python'); toggleSubmenu(); }}>
          <span className="dashboard-text">Python</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('IOT')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'iot' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('iot'); toggleSubmenu(); }}>
          <span className="dashboard-text">IOT</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('ML')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'ml' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('ml'); toggleSubmenu(); }}>
          <span className="dashboard-text">ML</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('AI')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'ai' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('ai'); toggleSubmenu(); }}>
          <span className="dashboard-text">AI</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('Data Structures')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'datastructures' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('datastructures'); toggleSubmenu(); }}>
          <span className="dashboard-text">Data Structures</span>
        </li>
      </Link>

      <Link to='/test/test-access' onClick={() => handleSkillType('Others')} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'others' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('Others'); toggleSubmenu(); }}>
          <span className="dashboard-text">Others</span>
        </li>
      </Link>

    </ul>
  );

  const MCQOptions = () => (
    <ul className='test-options' style={{ paddingLeft: '20px' }}>

      <Link onClick={() => { handlePre('Aptitude'); toggleAptitudeMenu(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'aptitude' ? 'active' : ''}`} onClick={() => handleMenuItemClick('aptitude')}>
          <span className="dashboard-text">Aptitude</span>
          {isAptitudeOpen && <img src={Downarrow} alt="Down Arrow" className="images12" />}
        </li>
      </Link>
      {isAptitudeOpen && <SkilltypeAptitude />}

      <Link to='/test/test-access' onClick={() => { handlePre('Softskills'); handleSkillType('') }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'softskills' ? 'active' : ''}`} onClick={() => handleMenuItemClick('softskills')}>
          <span className="dashboard-text">Softskills</span>
        </li>
      </Link>

      <Link onClick={() => { handlePre('Technical'); toggleTechMenu(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'technical' ? 'active' : ''}`} onClick={() => handleMenuItemClick('technical')}>
          <span className="dashboard-text">Technical</span>
          {isTechOpen && <img src={Downarrow} alt="Down Arrow" className="images12" />}
        </li>
      </Link>
      {isTechOpen && <SkilltypeTechnical />}

    </ul>
  );


  const CodingOptions = () => (
    <ul className='test-options' style={{ paddingLeft: '20px' }}>

      <Link onClick={() => { handlePre('Technical'); toggleTechMenuCode(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'technical' ? 'active' : ''}`} onClick={() => handleMenuItemClick('technical')}>
          <span className="dashboard-text">Technical</span>
        </li>
      </Link>

      {isTechOpenCode && <SkilltypeTechnical />}
    </ul>
  );


  const LMSOptions = () => (
    <ul className='test-options' style={{ paddingLeft: '15px', width: '100%' }}>

      <Link onClick={() => { handlePre('Aptitude'); toggleAptitudeMenuLMS(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'aptitude' ? 'active' : ''}`} onClick={() => handleMenuItemClick('aptitude')}>
          <span className="dashboard-text">Aptitude</span>
        </li>
      </Link>
      {isAptitudeOpenLMS && <SkilltypeAptitudeLMS />}

      <Link to='/Lms/upload-video' onClick={() => { handlePre('Softskills'); handleSkillTypeLMS(''); toggleSubmenu(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'softskills' ? 'active' : ''}`} onClick={() => handleMenuItemClick('softskills')}>
          <span className="dashboard-text">Softskills</span>
        </li>
      </Link>

      <Link onClick={() => { handlePre('Technical'); toggleTechMenuLMS(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'technical' ? 'active' : ''}`} onClick={() => handleMenuItemClick('technical')}>
          <span className="dashboard-text">Technical</span>
        </li>
      </Link>
      {isTechOpenLMS && <SkilltypeTechnicalLMS />}

      <Link to="/lms/map/" onClick={() => { handlePre('map-lms'); toggleTechMenuLMSmap(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'map-lms' ? 'active' : ''}`} onClick={() => handleMenuItemClick('map-lms')}>
          <span className="dashboard-text">Map LMS</span>
        </li>
      </Link>

      <Link to="/stufeedback/" onClick={() => { handlePre('stufeedback'); toggleTechMenuLMSmap(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'stufeedback' ? 'active' : ''}`} onClick={() => handleMenuItemClick('stufeedback')}>
          <span className="dashboard-text">Student Feedback</span>
        </li>
      </Link>

      <Link to="/Trainerfeedback/" onClick={() => { handlePre('Trainerfeedback'); toggleTechMenuLMSmap(); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'Trainerfeedback' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Trainerfeedback')}>
          <span className="dashboard-text">Trainer's Report</span>
        </li>
      </Link>

    </ul>
  );



  const QuestionsOptions = () => (
    <ul className='test-options' style={{ paddingLeft: '20px' }}>

      <Link to='/question/mcq' onClick={() => { handleTestType('MCQ Test'); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'mcq' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('mcq'); toggleSubmenu(); }}>
          <span className="dashboard-text">MCQ</span>
        </li>
      </Link>

      <Link to='/question/code' onClick={() => { handleTestType('Coding Test'); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'coding' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('coding'); toggleSubmenu(); }}>
          <span className="dashboard-text">Coding</span>
        </li>
      </Link>

      <Link to='/question-paper-table' onClick={() => { handleTestType('Coding Test'); }} onContextMenu={(e) => e.preventDefault()}>
        <li className={`test-option ${activeMenuItem === 'qp-paper' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('qp-paper'); toggleSubmenu(); }}>
          <span className="dashboard-text">Question Papers</span>
        </li>
      </Link>

    </ul>
  );


  return (
    <div className={`sidebar ${isOpen ? '' : 'collapsed'} `} style={{
      maxHeight: '100vh', overflowY: 'auto', scrollbarWidth: "thin",
    }}>
      <button className="toggle-btn" onClick={toggleSidebar} >
        <span className="arrow">{isOpen ? '<' : '>'}</span>
      </button>

      <div style={{ marginTop: "20px" }}>
        <nav className="sidebar-nav">
          <ul>
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <li className={activeMenuItem === 'dashboard' ? 'active' : ''} onClick={() => { handleMenuItemClick('dashboard'); toggleSubmenu(); }}>
                <div className={`test-section ${isdashopen ? 'open' : ''}`} style={{ width: '100%' }}>
                  <div className="test-header" onClick={toggledashMenu}>
                    <img src={DashboardIcon} alt="Dashboard" className='icon-image' />
                    <span className="dashboard-text">Dashboard</span>
                  </div>
                </div>
              </li>
            </Link>


            <li>
              <div className={`test-section ${isQuestionsOpen ? 'open' : ''}`} style={{ width: '100%' }}>
                <div className="test-header" onClick={toggleQuestionsMenu}>
                  <img src={questionsPng} alt="Learning Material" className='icon-image'  
                   style={{ width: '20px', height: '20px' }} />
                  <Link>Questions</Link>
                  {isQuestionsOpen && isOpen && (
                    <img src={Downarrow} alt="Down Arrow" className="images13" />
                  )}
                  {!isQuestionsOpen && isOpen && (
                    <img src={Downarrow} alt="Down Arrow" className="images13 rotate-up" />
                  )}
                </div>
                {isQuestionsOpen && isOpen && <QuestionsOptions />}


              </div>
            </li>



            <li>
              <div className="test-section" style={{ width: '100%' }}>
              <div className="test-header" onClick={toggleTestMenu}>
                  <img src={TestIcon} alt="Test" className='icon-image' />
                  <Link to='/test/test-schedules/'>Test</Link>
                  {isTestOpen && isOpen && (
                    <img src={Downarrow} alt="Down Arrow" className="images12" />
                  )}
                  {!isTestOpen && isOpen && (
                    <img src={Downarrow} alt="Down Arrow" className="images12 rotate-up" />
                  )}

                </div>
                {isTestOpen && isOpen && (
                  <ul className="test-options" >

                    <div className="test-section" style={{ paddingLeft: '20px', paddingTop: "8px" }}>
                      <div className="test-header" onClick={toggleMapTest}>

                        <Link onContextMenu={(e) => e.preventDefault()}>AddTest</Link>
                        {isMapOpen && isOpen && (
                          <img src={Downarrow} alt="Down Arrow" className="images13" />
                        )}
                        {!isMapOpen && isOpen && (
                          <img src={Downarrow} alt="Down Arrow" className="images13 rotate-up" />
                        )}

                      </div >
                      {isMapOpen && isOpen && (
                        <ul className="test-options" style={{ paddingLeft: '4px', paddingTop: "4px" }}>
                          <li className={`test-option ${activeMenuItem === 'mcqTest' ? 'active' : ''}`} onClick={() => handleMenuItemClick('mcqTest')}>


                            <Link onClick={() => { handleTestType('MCQ Test'); toggleMCQMenu(); }} onContextMenu={(e) => e.preventDefault()}>MCQTest</Link>

                          </li>
                          {isMCQOpen && isOpen && (
                            <ul className="test-options" style={{ paddingLeft: '15px' }}>

                              <Link
                                onClick={() => { handleMcqOp('Pre/Post Assessment'); togglePreMenu(); }}
                                onContextMenu={(e) => e.preventDefault()}
                              >
                                <li
                                  className={`test-option ${activeMenuItem === 'Pre/Post Assessment' ? 'active' : ''}`}
                                  onClick={() => handleMenuItemClick('Pre/Post Assessment')}
                                >
                                  <span className="dashboard-text">Pre/Post Assessment</span>
                                </li>
                              </Link>
                              {isPreOpen && isOpen && <MCQOptions />}

                              <Link
                                onClick={() => { handleMcqOp('Assessment'); toggleAssessMenu(); }}
                                onContextMenu={(e) => e.preventDefault()}
                              >
                                <li
                                  className={`test-option ${activeMenuItem === 'Assessment' ? 'active' : ''}`}
                                  onClick={() => handleMenuItemClick('Assessment')}
                                >
                                  <span className="dashboard-text">Assessment</span>
                                </li>
                              </Link>
                              {isAssessmentOpen && isOpen && <MCQOptions />}

                              <Link
                                onClick={() => { handleMcqOp('Mock/Interview'); toggleMockMenu(); }}
                                onContextMenu={(e) => e.preventDefault()}
                              >
                                <li
                                  className={`test-option ${activeMenuItem === 'mockTest/Interview' ? 'active' : ''}`}
                                  onClick={() => handleMenuItemClick('mockTest/Interview')}
                                >
                                  <span className="dashboard-text">Mock/Interview</span>
                                </li>
                              </Link>
                              {isMockOpen && isOpen && <MCQOptions />}

                              <Link
                                onClick={() => { handleMcqOp('Company Specific'); toggleComMenu(); }}
                                onContextMenu={(e) => e.preventDefault()}
                              >
                                <li
                                  className={`test-option ${activeMenuItem === 'companySpecific' ? 'active' : ''}`}
                                  onClick={() => handleMenuItemClick('companySpecific')}
                                >
                                  <span className="dashboard-text">Company Specific</span>
                                </li>
                              </Link>
                              {isComOpen && isOpen && <MCQOptions />}

                            </ul>
                          )}
                          <li className={`test-option ${activeMenuItem === 'codingTest' ? 'active' : ''}`} onClick={() => handleMenuItemClick('codingTest')}>


                            <Link onContextMenu={(e) => e.preventDefault()} onClick={() => { handleTestType('Coding Test'); toggleCodingMenu(); }}>CodingTest</Link>

                          </li>
                        </ul>
                      )}

                      {isCodingOpen && isOpen && (
                        <ul className="test-options" style={{ paddingLeft: '15px' }}>
                          <li className={`test-option ${activeMenuItem === 'Pre/Post Assessment' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Pre/Post Assessment')}>

                            <Link onContextMenu={(e) => e.preventDefault()} onClick={() => { handleCodingOp('Pre/Post Assessment'); togglePreMenuCode(); }}>Pre/Post Assessment</Link>

                          </li>
                          {isPreOpenCode && isOpen && <CodingOptions />}



                          {/*}      <li className={`test-option ${activeMenuItem === 'practiceTest' ? 'active' : ''}`} onClick={() => handleMenuItemClick('practiceTest')}>

                            <Link onContextMenu={(e) => e.preventDefault()} onClick={() => { handleCodingOp('Practice Test'); togglePracMenuCode(); }}>PracticeTest</Link>
                            
                          </li>
                          {isPracOpenCode && <CodingOptions />}     

                          */}

                          <li className={`test-option ${activeMenuItem === 'Assessment' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Assessment')}>

                            <Link onContextMenu={(e) => e.preventDefault()} onClick={() => { handleCodingOp('Assessment'); toggleAssessMenuCode(); }}>Assessment</Link>

                          </li>
                          {isAssessmentOpenCode && isOpen && <CodingOptions />}

                          <li className={`test-option ${activeMenuItem === 'mockTest/Interview' ? 'active' : ''}`} onClick={() => handleMenuItemClick('mockTest/Interview')}>

                            <Link onContextMenu={(e) => e.preventDefault()} onClick={() => { handleCodingOp('Mock/Interview'); toggleMockMenuCode(); }}>Mock/Interview</Link>

                          </li>
                          {isMockOpenCode && isOpen && <CodingOptions />}

                          <li className={`test-option ${activeMenuItem === 'companySpecific' ? 'active' : ''}`} onClick={() => handleMenuItemClick('companySpecific')}>

                            <Link onContextMenu={(e) => e.preventDefault()} onClick={() => { handleCodingOp('Company Specific'); toggleComMenuCode(); }}>CompanySpecific</Link>


                          </li>
                          {isComOpenCode && isOpen && <CodingOptions />}

                          <li>
                          </li>
                        </ul>
                      )}
                    </div>
                  </ul>
                )}
              </div>
            </li>

            
            <Link to="/reports" onContextMenu={(e) => {
              e.preventDefault();
              const newWindow = window.open('https://ccportal.co.in', '_blank', 'noopener,noreferrer');
              if (newWindow) newWindow.opener = null;
            }} style={{ color: "white", textDecoration: "none" }}>
              <li className={activeMenuItem === 'reports' ? 'active' : ''} onClick={() => { handleMenuItemClick('reports'); toggleReportsMenu(); toggleSubmenu(); }} >

                <img src={DashboardIcon} alt="reports" className='icon-image' />
                <span className="dashboard-text">Reports</span>
              </li>
            </Link>


            <li>
              <div className={`test-section ${isDatabaseOpen ? 'open' : ''}`} style={{ width: '100%' }}>
                <div className="test-header" onClick={toggleDatabaseMenu}>
                  <img src={DatabaseIcon} alt="Database" className='icon-image' />
                  <Link>Database</Link>
                  {isDatabaseOpen && isOpen && (
                    <img src={Downarrow} alt="Down Arrow" className="images13" />
                  )}
                  {!isDatabaseOpen && isOpen && (
                    <img src={Downarrow} alt="Down Arrow" className="images13 rotate-up" />
                  )}
                </div>
                {isDatabaseOpen && isOpen && (
                  <ul className="test-options" style={{ paddingLeft: '20px' }}>
                    <li className={`test-option ${activeMenuItem === 'uploadProfile' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('uploadProfile'); toggleSubmenu(); }}>

                      <Link to="/Database/upload-student" onContextMenu={(e) => {
                        e.preventDefault();
                        const newWindow = window.open('https://ccportal.co.in', '_blank', 'noopener,noreferrer');
                        if (newWindow) newWindow.opener = null;
                      }}>UploadProfile</Link>
                    </li>



                    {/*}  <li className={`test-option ${activeMenuItem === 'settings' ? 'active' : ''}`} onClick={() => handleMenuItemClick('settings')}>

                      <Link to="/Database/settings">Settings</Link>
                    </li>*/}
                    <li className={`test-option ${activeMenuItem === 'createAccount' ? 'active' : ''}`} onClick={() => { handleMenuItemClick('createAccount'); toggleSubmenu(); }}>

                      <Link to="/Database/login">CreateAccount</Link>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <Link to="/announce/" style={{ color: "white", textDecoration: "none" }}>

              <li
                className={activeMenuItem === 'announcement' ? 'active' : ''}
                onClick={() => {
                  handleMenuItemClick('announcement');
                  toggleSubmenu();
                }}
              >
                <div className={`test-section ${isannouncementopen ? 'open' : ''}`} style={{ width: '100%' }}>
                  <div className="test-header" onClick={toggleannounceMenu}>
                    <img src={announcement} alt="announcement" className='icon-announ' 
                   style={{ width: '20px', height: '20px' }} />
                    <span className="dashboard-text">Announcement</span>
                  </div>
                </div>
              </li>
            </Link>

            <Link to="/job" style={{ color: "white", textDecoration: "none" }}>
              <li className={activeMenuItem === 'job' ? 'active' : ''} onClick={() => { handleMenuItemClick('job'); toggleSubmenu(); }}>
                <div className={`test-section ${isjobopen ? 'open' : ''}`} style={{ width: '100%' }}>
                  <div className="test-header" onClick={togglejobMenu}>
                    <img src={ProfileIcon} alt="Profile" className='icon-image' />

                    <span className="dashboard-text">Job Posting</span>
                  </div>
                </div>
              </li>
            </Link>



          </ul>
        </nav>

      </div>
      <div className='select-option'>{selectedSkillTypeOption && (
        <React.Fragment>
          {console.log(selectedSkillTypeOption)}
          <p> {selectedSkillTypeOption}</p>
        </React.Fragment>)}</div>
      <div className='select-lms'>{selectedPreOption && (
        <React.Fragment>
          {console.log(selectedPreOption)}
          <p> {selectedPreOption}</p>
        </React.Fragment>)}</div>
      <div className='select-lms-skill'>{selectedSkillTypeOptionLMS && (
        <React.Fragment>
          {console.log(selectedSkillTypeOptionLMS)}
          <p> {selectedSkillTypeOptionLMS}</p>
        </React.Fragment>)}</div>
    </div>
  );
};

export default Sidebar;
