import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Pagination } from "react-bootstrap";
import "../../Styles/TrainingAdmin.css";
import {
  getInvoiceByIdApi,
  update_paymentApi,
  updateScheduleDateApi,
} from "../../api/endpoints";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import ErrorModal from "../auth/ErrorModal";
import { SearchContext } from "../../AllSearch/SearchContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
const InvoiceTB = () => {
  const [invoices, setInvoices] = useState([]);
  const [DateTime, setDateTime] = useState(new Date()); // State for storing the selected schedule date

  const [test_name, setTestName] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const { searchQuery } = useContext(SearchContext);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // search
  const searchTerm = searchQuery || search;

  // Fetch invoices when the component mounts
  useEffect(() => {
    getInvoiceByIdApi()
      .then((data) => setInvoices(data))
      .catch((error) => console.error("Error fetching invoices:", error));
  }, []);

  // Global filtering for invoices based on searchTerm
  const filteredInvoices = invoices.filter((invoice) => {
    return (
      !searchTerm ||
      Object.values(invoice).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });
  // search

  // To store selected dates for each invoice
  const handleCloseError = () => {
    setShowError(false);
  };
  useEffect(() => {
    getQuestionPapers();
  }, []); // Run once on component mount

  const getQuestionPapers = () => {
    getInvoiceByIdApi()
      .then((data) => {
        setInvoices(data);
      })
      .catch((error) =>
        console.error("Error fetching question papers:", error)
      );
  };

  const handleDownloadInvoice = (invoice) => {
    const doc = new jsPDF();

    // Get the current date in a suitable format (e.g., DD/MM/YYYY)
    const currentDate = new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const travelTotal =
      (invoice.travel_days || 0) * (invoice.travel_amount || 0);
    const foodTotal = (invoice.food_days || 0) * (invoice.food_amount || 0);
    const printTotal = (invoice.print_days || 0) * (invoice.print_amount || 0);

    const totalAmount = travelTotal + foodTotal + printTotal;
    const tdsAmount = totalAmount * 0.1;
    const netAmount = totalAmount - tdsAmount;

    // Add title
    const title = "Training Invoice";
    doc.setFontSize(24);
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - textWidth) / 2, 25);

    // Add current date and trainer details
    doc.setFontSize(13);
    doc.text(`Date: ${currentDate}`, 160, 42);
    doc.text("From", 14, 50);
    doc.text(
      `Trainer Name: ${invoice.trainer_id__trainer_name || "N/A"}`,
      14,
      62
    );
    doc.text("Address:", 14, 70);
    doc.text(` ${invoice.trainer_id__address || "N/A"}`, 14, 78);
    doc.text(` ${invoice.trainer_id__city || "N/A"}`, 14, 84);

    // Bank Details
    doc.text("Bank Details", 14, 100);
    doc.text(`Bank Name: ${invoice.trainer_id__bank_name || "N/A"}`, 14, 108);
    doc.text(
      `Branch Name: ${invoice.trainer_id__branch_name || "N/A"}`,
      14,
      116
    );
    doc.text(`Account No: ${invoice.trainer_id__account_no || "N/A"}`, 14, 126);
    doc.text(`IFSC Code: ${invoice.trainer_id__ifsc_code || "N/A"}`, 14, 134);
    doc.text(`PAN Number: ${invoice.trainer_id__pan_number || "N/A"}`, 14, 148);

    // Create table
    doc.autoTable({
      startY: 164,
      head: [
        ["S.No", "Purpose of Claim", "Attachment", "Days", "Amount", "Total"],
      ],
      body: [
        [
          "1",
          "Training",
          "N/A",
          invoice.training_days,
          invoice.training_amount,
          travelTotal.toFixed(2),
        ],
        [
          "2",
          "Travel",
          "Click here",
          invoice.travel_days,
          invoice.travel_amount,
          travelTotal.toFixed(2),
        ],
        [
          "3",
          "Food",
          "Click here",
          invoice.food_days,
          invoice.food_amount,
          foodTotal.toFixed(2),
        ],
        [
          "4",
          "Print",
          "Click here",
          invoice.print_days,
          invoice.print_amount,
          printTotal.toFixed(2),
        ],
      ],
    });

    const finalY = doc.lastAutoTable.finalY + 10;
    doc.text(`Gross: ${totalAmount.toFixed(2)}`, 140, finalY);
    doc.text(`TDS Deduction (10%): ${tdsAmount.toFixed(2)}`, 140, finalY + 10);
    doc.text(`Net Amount: ${netAmount.toFixed(2)}`, 140, finalY + 20);

    // Adding links to download respective PDFs
    const travelLinkY = finalY + 30;
    const foodLinkY = travelLinkY + 10;
    const miscLinkY = foodLinkY + 10;

    // Set link for travel expenses
    const travelPDF = generatePDFBase64(invoice, "travel");
    doc.setTextColor(0, 0, 255); // Change color to blue for links
    doc.textWithLink("Download Travel Expenses PDF", 14, travelLinkY, {
      url: travelPDF,
    });

    // Set link for food allowance
    const foodPDF = generatePDFBase64(invoice, "food");
    doc.textWithLink("Download Food Allowance PDF", 14, foodLinkY, {
      url: foodPDF,
    });

    // Set link for miscellaneous expenses
    const miscPDF = generatePDFBase64(invoice, "misc");
    doc.textWithLink("Download Miscellaneous Expenses PDF", 14, miscLinkY, {
      url: miscPDF,
    });

    // Save the main invoice PDF
    doc.save(`Invoice_${invoice.invoice_no}.pdf`);
  };

  // Function to generate PDF as Base64 string
  const generatePDFBase64 = (invoice, type) => {
    const pdfDoc = new jsPDF();
    let title = "";
    let textContent = "";

    switch (type) {
      case "travel":
        title = "Travel Expenses Details";
        textContent = invoice.travel_expenses_text || "No details available.";
        break;
      case "food":
        title = "Food Allowance Details";
        textContent = invoice.food_allowance_text || "No details available.";
        break;
      case "misc":
        title = "Miscellaneous Expenses Details";
        textContent = invoice.misc_expenses_text || "No details available.";
        break;
    }

    pdfDoc.text(title, 10, 10);
    pdfDoc.text(textContent, 10, 20);

    // Return Base64 string
    return pdfDoc.output("datauristring");
  };

  const filteredData = invoices.filter((item) => {
    // Ensure item.invoice_no is a string before calling toLowerCase
    const invoice = item.invoice_no || "";
    return invoice.toLowerCase().includes(test_name.toLowerCase());
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getPaginationItems = () => {
    const items = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage === 1) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage === totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  const handlePay = (id) => {
    console.log(`Payment initiated for invoice ID: ${id}`);

    // Call the API to update payment status
    update_paymentApi(id)
      .then(() => {
        console.log(`Payment status updated to 'Paid' for invoice ID: ${id}`);

        // Update the local state to reflect payment status
        setInvoices((prevInvoices) =>
          prevInvoices.map((invoice) =>
            invoice.id === id ? { ...invoice, payment_status: "Paid" } : invoice
          )
        );
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        setErrorMessage("Error updating payment status");
        setShowError(true);
      });
  };

  const [selectedDates, setSelectedDates] = useState({});

  const handleDateChange = (date, id) => {
    console.log(`Date changed for invoice ID: ${id}, New Date: ${date}`);

    setSelectedDates((prevDates) => ({
      ...prevDates,
      [id]: date, // Store the selected date for this invoice id
    }));
  };

  const handleUpdateSchedule = (id) => {
    const selectedDate = selectedDates[id];

    console.log(`Attempting to update schedule date for invoice ID: ${id}`);

    if (!selectedDate) {
      console.log("No date selected. Please select a date.");
      alert("Please select a date before updating.");
      return;
    }

    console.log(`Selected date for invoice ID ${id}: ${selectedDate}`);

    // Adjust the selectedDate for time zone offset before sending to the API
    const adjustedDate = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    );

    console.log("Original selected date object:", selectedDate);
    console.log(
      "Adjusted schedule date for API (ISO string):",
      adjustedDate.toISOString()
    );

    // Call the API to update the schedule_date
    updateScheduleDateApi(id, adjustedDate)
      .then(() => {
        console.log(
          `API call successful: Schedule date updated for invoice ID: ${id}`
        );

        // Update the local state with the new schedule_date
        setInvoices((prevInvoices) =>
          prevInvoices.map((invoice) =>
            invoice.id === id
              ? { ...invoice, schedule_date: adjustedDate }
              : invoice
          )
        );
      })
      .catch((error) => {
        console.error("Error updating schedule date:", error);
        setErrorMessage("Error updating schedule date");
        setShowError(true);
      });
  };

  return (
    <div>
      <div className="product-table-container">
        <h2>Invoice Data</h2>
        <br />

        <input
          className="search-box"
          type="text"
          placeholder="Search..."
          value={test_name}
          onChange={(e) => setTestName(e.target.value)}
        />
        <div className="table-responsive-questions">
          <table className="product-table">
            <thead className="table-thead">
              <tr>
                <th>Invoice No</th>
                <th>Trainer Name</th>
                <th>Payment Status</th>
                <th>Schedule</th>
              </tr>
            </thead>
            <tbody className="table-tbody">
              {currentData.map((item) => (
                <tr key={item.id} className="table-row">
                  <td>
                    <Link
                      style={{ color: "white" }}
                      onClick={() => handleDownloadInvoice(item)}
                      className="invoice-download-btn"
                    >
                      {item.invoice_no}
                    </Link>
                  </td>
                  <td>{item.trainer_id__trainer_name}</td>
                  <td>
                    {item.payment_status === "Paid" ? (
                      <button
                        className="button-ques-save"
                        style={{ width: "70px" }}
                        disabled
                      >
                        Paid
                      </button>
                    ) : (
                      <button
                        className="button-ques-save"
                        style={{ width: "70px" }}
                        onClick={() => handlePay(item.id)}
                      >
                        Pay
                      </button>
                    )}
                  </td>

                  <td style={{ width: "350px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* Smaller DatePicker */}
                      <DatePicker
                        selected={
                          selectedDates[item.id] ||
                          (item.schedule_date
                            ? new Date(item.schedule_date)
                            : null)
                        }
                        onChange={(date) => handleDateChange(date, item.id)}
                        showTimeSelect
                        timeFormat="hh:mm aa"
                        timeIntervals={15}
                        dateFormat="dd-MM-yyyy, h:mm aa"
                        timeCaption="Time"
                        className="input-date-custom-inv"
                        autoComplete="off"
                        required
                      />

                      {/* Smaller Update Button */}
                      <button
                        className="button-ques-save"
                        style={{ width: "70px", padding: "5px" }}
                        onClick={() => handleUpdateSchedule(item.id)}
                      >
                        Update
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p></p>
        <div className="dis-page">
          <Form.Group
            controlId="itemsPerPageSelect"
            style={{ display: "flex" }}
          >
            <Form.Label style={{ marginRight: "10px" }}>Display:</Form.Label>
            <Form.Control
              as="select"
              style={{ width: "50px", boxShadow: "none", outline: "none" }}
              className="label-dis"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset page to 1 when items per page changes
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Form.Control>
          </Form.Group>

          <Pagination className="pagination-custom">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {getPaginationItems()}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
      <ErrorModal
        show={showError}
        handleClose={handleCloseError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default InvoiceTB;
