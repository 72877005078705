import React, { useState, useEffect } from "react";
import { Col, Row, Form, button } from "react-bootstrap";
import Select from "react-select";
import {
  updateQuestionPaperApi,
  getQuestionPaperByIdApi,
} from "../../api/endpoints";
import { useParams } from "react-router-dom";
import ErrorModal from "../auth/ErrorModal";
import { useNavigate } from "react-router-dom";
import Nextarrow from "../../assets/Images/nextarrow.png";
import back from "../../assets/Images/backarrow.png";
const QuestionPaper = () => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { id } = useParams();
  console.log("print id", id);
  const [formData, setFormData] = useState({
    question_paper_name: "",
    duration_of_test: "",
    topic: "",
    sub_topic: "",
  });

  useEffect(() => {
    // Fetch question paper by ID when the component mounts
    getQuestionPaperByIdApi(id)
      .then((data) => {
        // Set the form data with the fetched question paper details
        setFormData({
          question_paper_name: data.question_paper_name || "",
          duration_of_test: data.duration_of_test || "",
          topic: data.topic || "",
          sub_topic: data.sub_topic || "",
        });
      })
      .catch((error) => {
        console.error("Failed to fetch question paper", error);
        setErrorMessage("Failed to fetch question paper. Please try again.");
        setShowError(true);
      });
  }, [id]); // Only runs once when component mounts or when id changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();

    // Fetch existing question papers to validate unique topic-subtopic pairs
    updateQuestionPaperApi(id, formData)
      .then((result) => {
        navigate("/question-paper-table");
        // Handle success (e.g., show a success message or navigate to another page)
        console.log("Question paper updated successfully", result);
      })
      .catch((error) => {
        console.error("Failed to update question paper", error);
        alert("Failed to update question paper. Check console for details.");
      });
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <div className="form-ques">
      <div className="form-ques-codepap">
        <div>
          <form
            className="form-ques-upd"
            onSubmit={(e) => handleSubmit(e, formData)}
          >
            <Row md={12}>
              <Col>
                <div className="questionName" controlId="question_paper_name">
                  <label className="label6-ques">Question Paper Name</label>
                  <p></p>
                  <input
                    type="text"
                    className="input-ques"
                    name="question_paper_name"
                    required
                    placeholder=""
                    autoComplete="off"
                    value={formData.question_paper_name}
                    onChange={handleInputChange}
                  />{" "}
                </div>
              </Col>

              <Col>
                <div className="duration" controlId="duration_of_test">
                  <label className="label7-ques">Duration of the Test</label>
                  <p></p>
                  <input
                    type="number"
                    name="duration_of_test"
                    required
                    placeholder=""
                    autoComplete="off"
                    className="input-ques-dur"
                    min="0"
                    value={formData.duration_of_test}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <p></p>

            <Row md={12}>
              <Col>
                <div controlId="topic">
                  <label className="label6-ques">Topic</label><p></p>
                  <input
                    type="text"
                    autoComplete="off"
                    name="topic"
                    required
                    placeholder=""
                    className="input-ques-top"
                    value={formData.topic}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col>
                <div controlId="selectedSubTopic">
                  <label className="label7-ques"> Sub Topic</label><p></p>
                  <input
                    type="text"
                    autoComplete="off"
                    name="sub_topic"
                    required
                    placeholder=""
                    className="input-ques-top"
                    value={formData.sub_topic}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <p style={{ height: "50px" }}></p>
            <Row>
              <Col>
                <div className="button-container-lms">
                  <button
                    className="button-ques-save btn btn-secondary back-button-lms"
                    style={{
                      width: "100px",
                      color: "black",
                      height: "50px",
                      backgroundColor: "#F1A128",
                      cursor: "not-allowed",
                    }}
                    disabled
                  >
                    <img src={back} className="nextarrow"></img>
                    <span className="button-text">Back</span>
                  </button>
                  <button
                    style={{ width: "100px" }}
                    className="button-ques-save save-button-lms"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    className="button-ques-save btn btn-secondary next-button-lms"
                    disabled
                    style={{
                      width: "100px",
                      backgroundColor: "#F1A128",
                      cursor: "not-allowed",
                      width: "100px",
                      color: "black",
                      height: "50px",
                    }}
                  >
                    <span className="button-text">Next</span>{" "}
                    <img
                      src={Nextarrow}
                      className="nextarrow"
                      style={{ color: "#6E6D6C" }}
                    ></img>
                  </button>
                </div>
              </Col>
            </Row>
          </form>
          <p></p>
        </div>
      </div>
      <ErrorModal
        show={showError}
        handleClose={handleCloseError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default QuestionPaper;
