import React, { useState, useEffect, useContext } from 'react';
import { getCourseContentFeedbackApi, getDistinct_test_API } from '../../../src/api/endpoints';
import '../../Styles/TrainingAdmin.css';
import back from '../../assets/Images/backarrow.png'
import { Table, Form, Pagination } from 'react-bootstrap';
import Next from '../../assets/Images/nextarrow.png';
import { SearchContext } from '../../AllSearch/SearchContext';
import Download from '../../assets/Images/download.png'
import * as XLSX from 'xlsx';




const FilterDropdown = ({ options, selectedValue, onChange, className }) => {
  return (
    <select
      value={selectedValue || ''}
      onChange={(e) => onChange(e.target.value)}
      className={`filter-dropdown ${className}`}
    >
      <option value="">All</option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};





const StudentsFeedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [currentTrainerIndex, setCurrentTrainerIndex] = useState(0); // Track the index of the currently displayed trainer
  const [error, setError] = useState(null);
  const [trainerNameFilter, setTrainerNameFilter] = useState('');
  const [feedbackFilter, setFeedbackFilter] = useState('');
  const { searchQuery } = useContext(SearchContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = feedback.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(feedback.length / itemsPerPage);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const [filtersSt, setFiltersSt] = useState({});
  const [search, setSearch] = useState('');




  const handleFilterChangeSt = (key, value) => {
    setFiltersSt((prevFilters) => ({ ...prevFilters, [key]: value }));
  };



  const getPaginationItems = () => {
    const items = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage === 1) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage === totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };


  useEffect(() => {
    // Fetch trainer data when the component mounts or when filters change
    fetchFeedback();
  }, [trainerNameFilter, feedbackFilter, searchQuery, search, filtersSt]);

  const fetchFeedback = async () => {
    try {
      const response = await getCourseContentFeedbackApi();

      // Filter response based on all filter criteria, including college and trainer names
      const filteredFeedback = response.filter(item =>
        (filtersSt.college_name ? item.college_name === filtersSt.college_name : true) &&  // Filter by college name
        (filtersSt.trainer_name ? item.trainer_name === filtersSt.trainer_name : true) &&  // Filter by trainer name
        (searchQuery ? (
          (item.student_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.college_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.department_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.topic?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.sub_topic?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.trainer_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.dtm_session?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.feedback?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.remarks?.toLowerCase() || '').includes(searchQuery.toLowerCase())
        ) : true) &&  // Filter by trainer name
        (search ? (
          (item.student_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.college_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.department_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.topic?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.sub_topic?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.trainer_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.dtm_session?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.feedback?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.remarks?.toLowerCase() || '').includes(search.toLowerCase())
        ) : true)
      );

      setFeedback(filteredFeedback); // Set the filtered data to the state
    } catch (error) {
      setError('Failed to fetch feedback data');
      console.error(error);
    }
  };






  const getDistinctFilters = () => {
    getDistinct_test_API()
      .then(data => {
        setDistinctTests(data);
      })
      .catch(error => console.error('Error fetching distinct filters:', error));
  };

  const [placeholderClg, setPlaceholderClg] = useState("Select College");
  const [distinctTests, setDistinctTests] = useState({});


  const [filters, setFilters] = useState({
    student_name: '',
    college_name: '',
    department_name: '',
    topic: '',
    sub_topic: '',
    trainer_name: '',
    dtm_session: '',
    feedback: '',
    remarks: '',
  });


  const exportToExcel = () => {
    const filteredData = feedback.map(({ id, student_name, department_id, trainer_name, topic_id, skill_id, dtm_created, college_id, ...rest }) => rest); // Exclude id field
    const headerMap = {
      college_name: 'CollegeName',
      department_name: 'DepartmentName',
      topic: 'Topic',
      sub_topic: 'Sub Topic',
      dtm_session: 'Date',
      feedback: 'Feedback',
      remarks: 'Remarks',
    };

    const wsData = filteredData.map(candidate => {
      const modifiedCandidate = {};
      for (let key in candidate) {
        if (headerMap[key]) {
          modifiedCandidate[headerMap[key]] = candidate[key];
        } else {
          modifiedCandidate[key] = candidate[key];
        }
      }
      return modifiedCandidate;
    });

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Feedback Report");
    XLSX.writeFile(wb, "feedback_report.xlsx");
  };


  const filterOptions = {
    college_name: [...new Set(feedback.map((candidate) => candidate.college_name || ''))],
    trainer_name: [...new Set(feedback.map((candidate) => candidate.trainer_name || ''))],
  };



  return (
    <div className="form-ques-stufeed">
      <h2>Sudents  feedback</h2>
      {/*}  {error && <p>{error}</p>}   */}
      <div className="filter-container">
        <input
          className="search-box-db-nondb"
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <button className="button-ques-save" onClick={exportToExcel} style={{ width: "100px", marginRight: '20px' }}><img src={Download} className='nextarrow'></img><span>Export</span></button>


      </div><p></p>
      <div className="table-responsive-feed">
        <table className="product-table">
          <thead className="table-thead" style={{ textAlign: "center" }}>
            <tr className="header-row">
              <th className="title-place" style={{ textAlign: "center" }}>Student Name</th>
              <th className="title-place" style={{ textAlign: "center" }}>College Name

                <FilterDropdown
                  options={filterOptions.college_name || []}
                  selectedValue={filtersSt.college_name}
                  onChange={(value) => handleFilterChangeSt('college_name', value)}
                  className="dropdown-filter-college"
                />

              </th>
              <th className="title-place" style={{ textAlign: "center" }}>Department</th>
              <th className="title-place" style={{ textAlign: "center" }}>Topic</th>
              <th className="title-place" style={{ textAlign: "center" }}>Sub Topic</th>
              <th className="title-place" style={{ textAlign: "center" }}>Trainer Name

                <FilterDropdown
                  options={filterOptions.trainer_name || []}
                  selectedValue={filtersSt.trainer_name}
                  onChange={(value) => handleFilterChangeSt('trainer_name', value)}
                  className="dropdown-filter-trainer"
                />
              </th>
              <th className="title-place" style={{ textAlign: "center" }}>Session Date</th>
              <th className="title-place" style={{ textAlign: "center" }}>Feedback</th>
              {/*}  <th className="title-place" style={{ textAlign: "center" }}>Remarks</th>  */}
            </tr>
          </thead>

          <tbody className="table-tbody" style={{ fontSize: '16px' }}>
            {currentData.length > 0 ? (
              currentData.map((trainer, index) => (
                <tr key={index} className="table-row">
                  <td style={{ textAlign: "center" }}>{trainer.student_name}</td>
                  <td style={{ textAlign: "center" }}>{trainer.college_name}</td>
                  <td style={{ textAlign: "center" }}>{trainer.department_name}</td>
                  <td style={{ textAlign: "center" }}>{trainer.topic}</td>
                  <td style={{ textAlign: "center" }}>{trainer.sub_topic}</td>
                  <td style={{ textAlign: "center" }}>{trainer.trainer_name}</td>
                  <td style={{ textAlign: "center" }}>{trainer.dtm_session}</td>
                  <td style={{ textAlign: "center" }}>{trainer.feedback}</td>
                  {/*}  <td style={{ textAlign: "center" }}>{trainer.remarks}</td>  */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>No feedback found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className='dis-page'>
        <Form>
          <Form.Group controlId="itemsPerPageSelect" style={{ display: 'flex' }}>
            <Form.Label style={{ marginRight: '10px' }}>Display:</Form.Label>
            <Form.Control
              className='label-dis'
              style={{ width: "50px", boxShadow: 'none', outline: 'none' }}
              as="select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Form.Control>
          </Form.Group>
        </Form>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {getPaginationItems()}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div >
  );
};

export default StudentsFeedback;
