import React, { useState, useRef, useEffect } from "react";
import {
  getcollegeApi,
  addInvoiceApi,
  getTrainerApi,
  getTrainerByUsername,
} from "../../api/endpoints";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker"; // Adjust the import according to the library you're using
import Select from "react-select"; // Import Select if using react-select
import "react-datepicker/dist/react-datepicker.css"; // Import styles for DatePicker
import jsPDF from "jspdf";
import "jspdf-autotable";
import ErrorModal from "../../Components/auth/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#39444e",
    color: "#fff", // Text color
    borderColor: state.isFocused ? "" : "#ffff", // Border color on focus
    boxShadow: "none", // Remove box shadow
    "&:hover": {
      borderColor: state.isFocused ? "#ffff" : "#ffff", // Border color on hover
    },
    "&.css-1a1jibm-control": {
      // Additional styles for the specific class
    },
    "@media (max-width: 768px)": {
      // Adjust for mobile devices
      fontSize: "12px", // Smaller font size

      width: "100%",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#ffff", // Text color for selected value
    "@media (max-width: 768px)": {
      // Adjust for mobile devices
      fontSize: "12px", // Smaller font size
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#39444e"
      : state.isFocused
        ? "#39444e"
        : "#39444e",
    color: "#ffff", // Text color
    "&:hover": {
      backgroundColor: "#39444e", // Background color on hover
      color: "#ffff", // Text color on hover
    },
    "@media (max-width: 768px)": {
      // Adjust for mobile devices
      fontSize: "12px", // Smaller font size
      // width: "98%",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#39444e",
    "@media (max-width: 768px)": {
      // Adjust for mobile devices
      fontSize: "12px", // Smaller font size
    },
  }),
};

function InvoiceForm({ username }) {
  const [formData, setFormData] = useState({
    college_id: null,
    misc_expenses: null,
    travel_expenses: null,
    food_allowance: null,
    misc_expenses_type: "",
    trainer_id: "",
    dtm_start: "",
    dtm_end: "",
    overall_feedback: "",
    food_days: "",
    travel_amount: "",
    print_amount: "",
    food_amount: "",
    travel_days: "",
    print_days: "",
    invoice_no: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null); // New state for invoice details
  const [college, setCollege] = useState([]); // For storing college options
  const [trainer, setTrainer] = useState([]); // For storing trainer options
  const [trainerId, setTrainerId] = useState(""); // For storing selected trainer ID
  const [selectedCollege, setSelectedCollege] = useState(null); // For storing selected college
  const [startDateTime, setStartDateTime] = useState(null); // For start date
  const [endDateTime, setEndDateTime] = useState(null); // For end date
  const [fileNames, setFileNames] = useState({
    miscExpenses: "",
    travelExpenses: "",
    foodAllowance: "",
  });

  // Create a ref for the file input
  const miscExpensesInputRef = useRef(null);
  const foodAllowanceInputRef = useRef(null);
  const travelExpensesInputRef = useRef(null);
  const handleCloseError = () => {
    setShowError(false);
  };

  const handleFileChange = (e) => {
    // const { name, files } = e.target;
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: files[0],
    // }));
    const { name, files } = e.target;
    if (files.length > 0) {
      console.log("File selected:", files[0].name);
      setFileNames((prev) => ({
        ...prev,
        [name]: files[0].name,
      }));
    }
  };

  const handleUploadClick = () => {
    if (miscExpensesInputRef.current) {
      // Ensure the ref is defined before triggering the click
      miscExpensesInputRef.current.click();
    } else {
      console.error("The file input ref is not defined.");
    }
  };
  const handleTravelExpensesUploadClick = () => {
    if (travelExpensesInputRef.current) {
      travelExpensesInputRef.current.click();
    } else {
      console.error("The travelExpenses file input ref is not defined.");
    }
  };

  const handleFoodAllowanceUploadClick = () => {
    if (foodAllowanceInputRef.current) {
      foodAllowanceInputRef.current.click();
    } else {
      console.error("The foodAllowance file input ref is not defined.");
    }
  };

  useEffect(() => {
    async function fetchInvoiceDetails() {
      try {
        const data = await getTrainerByUsername(username);
        console.log("API response:", data); // Check the whole response

        if (data && Array.isArray(data) && data.length > 0) {
          const courseSchedule = data[0]; // Assuming you want the first item

          setInvoiceDetails({
            collegeName: courseSchedule.collegeName,
            trainer_name: courseSchedule.trainer_name,
            bank_name: courseSchedule.bank_name,
            branch_name: courseSchedule.branch_name,
            account_no: courseSchedule.account_no,
            ifsc_code: courseSchedule.ifsc_code,
            city: courseSchedule.city,
            pan_number: courseSchedule.pan_number,
            Start_Date: courseSchedule.Start_Date,
            End_Date: courseSchedule.End_Date,
            address: courseSchedule.address,
          });

          // Set invoice details directly from courseSchedule
          setFormData((prevFormData) => ({
            ...prevFormData,
            invoice_no: courseSchedule.invoice_no,
            travel_amount: courseSchedule.travel_amount || "",
            print_amount: courseSchedule.print_amount || "",
            food_amount: courseSchedule.food_amount || "",
            travel_days: courseSchedule.travel_days || "",
            print_days: courseSchedule.print_days || "",
            food_days: courseSchedule.food_days || "",
            training_days: courseSchedule.training_days || "",
            training_amount: courseSchedule.training_amount || "",
            misc_expenses: courseSchedule.misc_expenses || "",
            travel_expenses: courseSchedule.travel_expenses || "",
            food_allowance: courseSchedule.food_allowance || "",
            misc_expenses_type: courseSchedule.misc_expenses_type || "",

            overall_feedback: courseSchedule.overall_feedback || "",
          }));

          console.log("Form data set:", formData);
        } else {
          console.error(
            "Error: No course schedule found for the given username."
          );
          setErrorMessage("No course schedule found for the given username.");
          setShowError(true);
        }
      } catch (error) {
        console.error("Error fetching course schedule:", error);
        setErrorMessage("Error fetching course schedule.");
        setShowError(true);
      }
    }

    fetchInvoiceDetails();
  }, [username]);

  console.log("user", username);
  useEffect(() => {
    // Fetch College Data
    getcollegeApi()
      .then((data) => {
        setCollege(
          data.map((item) => ({ value: item.id, label: item.college }))
        );
      })
      .catch((error) => console.error("Error fetching College:", error));

    // Fetch Trainer Data and find trainer ID that matches the username
    getTrainerApi()
      .then((data) => {
        // Set Trainer dropdown options
        setTrainer(
          data.map((item) => ({ value: item.id, label: item.user_name }))
        );

        // Find trainer ID based on the logged-in username
        const matchingTrainer = data.find(
          (trainer) => trainer.user_name === username
        );

        if (matchingTrainer) {
          console.log("Trainer ID for the logged-in user:", matchingTrainer.id);
          // You can set the trainer ID in your state if needed
          setTrainerId(matchingTrainer.id); // Assuming you have a state to store trainer ID
        } else {
          console.log("No matching trainer found for the username:", username);
        }
      })
      .catch((error) => console.error("Error fetching trainers:", error));
  }, [username]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    const newErrors = {};

    // Conditional validation for file fields based on amount fields
    if (formData.travel_amount && !travelExpensesInputRef.current.files[0]) {
      setErrorMessage("Please upload the required file");
        newErrors.travelExpenses = 'Please upload a file for Travel Expenses.';
        setErrorMessage("Please upload the required file");
    }
    if (formData.print_amount && !miscExpensesInputRef.current.files[0]) {
      setErrorMessage("Please upload the required file");
      newErrors.miscExpenses = 'Please upload a file for Misc Expenses.';
      
  }
  if (formData.food_amount && !foodAllowanceInputRef.current.files[0]) {
      newErrors.foodAllowance = 'Please upload a file for Food Allowance.';
      setErrorMessage("Please upload the required file");
  }
    if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    setIsSubmitting(false);
    return;  // Prevent form submission
} else {
    setErrors({});
}
    
    // Prepare the data to be submitted, including the start and end date
    const invoiceData = {
      ...formData,
      trainer_id: trainerId, // Ensure trainer_id is added from state
      college_id: selectedCollege ? selectedCollege.value : null, // Ensure college_id is added correctly
      dtm_start: startDateTime ? startDateTime.toISOString() : null, // Store the start date in ISO format
      dtm_end: endDateTime ? endDateTime.toISOString() : null, // Store the end date in ISO format
      misc_expenses: miscExpensesInputRef.current.files[0] || null, // Ensure to include the file
      travel_expenses: travelExpensesInputRef.current.files[0] || null, // Ensure to include the file
      food_allowance: foodAllowanceInputRef.current.files[0] || null,
    };

    console.log("Submitting form data:", invoiceData); // Log for debugging

    try {
      const result = await addInvoiceApi(invoiceData);
      
      setErrorMessage("Form submitted successfully");
      setShowError(true);
      setCollege(null);
      setEndDateTime(null);
      setStartDateTime(null);
      e.target.reset();

    } catch (error) {
      setErrorMessage(`Error submitting form: ${error.message}`);
      setShowError(true);
      console.error("Submission error details:", error);
    }
    setIsSubmitting(false);

  };
  const handlePreview = (e) => {
    // Prevent default form submission behavior
    e.preventDefault();

    const doc = new jsPDF();

    // Get the current date in a suitable format (e.g., DD/MM/YYYY)
    const currentDate = new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // Calculate totals
    const travelTotal = (formData.travel_days || 0) * (formData.travel_amount || 0);
    const foodTotal = (formData.food_days || 0) * (formData.food_amount || 0);
    const printTotal = (formData.print_days || 0) * (formData.print_amount || 0);

    const totalAmount = travelTotal + foodTotal + printTotal;
    const tdsAmount = totalAmount * 0.1;
    const netAmount = totalAmount - tdsAmount;

    // Add title
    const title = "Training Invoice";
    const titleFontSize = 24;
    doc.setFontSize(titleFontSize);

    // Calculate text width and center it
    const textWidth = doc.getTextWidth(title);
    const pageWidth = doc.internal.pageSize.getWidth();

    // Set the background to fill the entire page width with reduced height
    doc.setFillColor(200); // Grey color
    doc.rect(0, 10, pageWidth, 20, "F"); // Full-width background rectangle with reduced height (20)

    // Set the font to bold and add the centered title text
    doc.setFont("Helvetica", "bold");

    // Center the text but only affect the text's position, not the background
    const xPosition = (pageWidth - textWidth) / 2;
    doc.text(title, xPosition, 25); // Centered text with the Y position adjusted to fit within reduced background height

    // Add current date and trainer details
    doc.setFontSize(13);
    doc.text(`Date: ${currentDate}`, 160, 42);
    doc.text("From", 14, 50);
    doc.text(`Trainer Name: ${invoiceDetails.trainer_name || "N/A"}`, 14, 62);
    doc.text("Address:", 14, 70);
    doc.text(` ${invoiceDetails.address || "N/A"}`, 14, 78);
    doc.text(` ${invoiceDetails.city || "N/A"}`, 14, 84);
    doc.text("Bank Details", 14, 100);
    doc.text(`Bank Name: ${invoiceDetails.bank_name || "N/A"}`, 14, 108);
    doc.text(`Branch Name: ${invoiceDetails.branch_name || "N/A"}`, 14, 116);
    doc.text(`Account No: ${invoiceDetails.account_no || "N/A"}`, 14, 126);
    doc.text(`IFSC Code: ${invoiceDetails.ifsc_code || "N/A"}`, 14, 134);
    doc.text(`PAN Number: ${invoiceDetails.pan_number || "N/A"}`, 14, 148);

    // Convert file input into URLs (if files exist)
    const travelFileLink = formData.travel_expenses ? URL.createObjectURL(formData.travel_expenses) : null;
    const foodFileLink = formData.food_allowance ? URL.createObjectURL(formData.food_allowance) : null;
    const printFileLink = formData.misc_expenses ? URL.createObjectURL(formData.misc_expenses) : null;

    // Create table with autoTable plugin
    doc.autoTable({
      startY: 164, // Adjust the starting Y coordinate to account for the bank details
      head: [["S.No", "Purpose of Claim", "Attachment", "Days", "Amount", "Total"]],
      body: [
        [
          "1",
          `Training for ${invoiceDetails.collegeName || "N/A"}`,
          "N/A",
          formData.training_days || "N/A",
          formData.training_amount || "N/A",
          travelTotal.toFixed(2),
        ],
        [
          "2",
          "Travel",
          travelFileLink ? { text: "Link", link: travelFileLink } : "N/A",
          formData.travel_days || "N/A",
          formData.travel_amount || "N/A",
          travelTotal.toFixed(2),
        ],
        [
          "3",
          "Food",
          foodFileLink ? { text: "Link", link: foodFileLink } : "N/A",
          formData.food_days || "N/A",
          formData.food_amount || "N/A",
          foodTotal.toFixed(2),
        ],
        [
          "4",
          "Print",
          printFileLink ? { text: "Link", link: printFileLink } : "N/A",
          formData.print_days || "N/A",
          formData.print_amount || "N/A",
          printTotal.toFixed(2),
        ],
      ],
      theme: "grid",
      styles: {
        fontSize: 14, // Increase font size for the table content
      },
      headStyles: {
        fillColor: [211, 211, 211],
        textColor: [0, 0, 0],
        fontSize: 14,
        fontStyle: "bold",
      },
      bodyStyles: {
        fontSize: 12,
      },
      didDrawCell: function (data) {
        // Make links clickable inside the table
        const { cell, column, row } = data;

        // Check if this is the "Attachment" column and has a link object
        if (column.index === 2 && cell.text[0] === "Link") {
          const link = row.raw[2].link;
          if (link) {
            doc.link(cell.textPos.x, cell.textPos.y, cell.width, cell.height, { url: link });
          }
        }
      },
    });

    // Add totals to the right of the table
    const finalY = doc.lastAutoTable.finalY + 10;
    const rightMargin = 140;
    doc.text(`Gross: ${totalAmount.toFixed(2)}`, rightMargin, finalY);
    doc.text(`TDS Deduction (10%): ${tdsAmount.toFixed(2)}`, rightMargin, finalY + 10);
    doc.text(`Net Amount: ${netAmount.toFixed(2)}`, rightMargin, finalY + 20);

    // Download the PDF without submitting data
    doc.save("invoice.pdf");
  };



  /*
  const handlePreview = () => {
    const doc = new jsPDF();

    // Get the current date in a suitable format (e.g., DD/MM/YYYY)
    const currentDate = new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // Calculate totals
    const travelTotal =
      (formData.travel_days || 0) * (formData.travel_amount || 0);
    const foodTotal = (formData.food_days || 0) * (formData.food_amount || 0);
    const printTotal =
      (formData.print_days || 0) * (formData.print_amount || 0);

    const totalAmount = travelTotal + foodTotal + printTotal;
    const tdsAmount = totalAmount * 0.1;
    const netAmount = totalAmount - tdsAmount;

    // Add title
    const title = "Training Invoice";
    const titleFontSize = 24;
    doc.setFontSize(titleFontSize);

    // Calculate text width and center it
    const textWidth = doc.getTextWidth(title);
    const pageWidth = doc.internal.pageSize.getWidth();

    // Set the background to fill the entire page width with reduced height
    doc.setFillColor(200); // Grey color
    doc.rect(0, 10, pageWidth, 20, "F"); // Full-width background rectangle with reduced height (20)

    // Set the font to bold and add the centered title text
    doc.setFont("Helvetica", "bold");

    // Center the text but only affect the text's position, not the background
    const xPosition = (pageWidth - textWidth) / 2;
    doc.text(title, xPosition, 25); // Centered text with the Y position adjusted to fit within reduced background height

    // Add current date and trainer details
    doc.setFontSize(13);
    doc.text(`Date: ${currentDate}`, 160, 42);
    doc.text("From", 14, 50);
    doc.text(`Trainer Name: ${invoiceDetails.trainer_name || "N/A"}`, 14, 62);
    doc.text("Address:", 14, 70);
    doc.text(` ${invoiceDetails.address || "N/A"}`, 14, 78);
    doc.text(` ${invoiceDetails.city || "N/A"}`, 14, 84);
    // Add a space before Bank Details
    doc.text("Bank Details", 14, 100); // Heading for bank details
    doc.text(`Bank Name: ${invoiceDetails.bank_name || "N/A"}`, 14, 108);
    doc.text(`Branch Name: ${invoiceDetails.branch_name || "N/A"}`, 14, 116);
    doc.text(`Account No: ${invoiceDetails.account_no || "N/A"}`, 14, 126);
    doc.text(`IFSC Code: ${invoiceDetails.ifsc_code || "N/A"}`, 14, 134);

    // PAN Number
    doc.text(`PAN Number: ${invoiceDetails.pan_number || "N/A"}`, 14, 148);

    // Create table with autoTable plugin
    doc.autoTable({
      startY: 164, // Adjust the starting Y coordinate to account for the bank details
      head: [
        ["S.No", "Purpose of Claim", "Attachment", "Days", "Amount", "Total"],
      ],
      body: [
        [
          "1",
          `Training for ${invoiceDetails.collegeName || "N/A"}`,
          "N/A",
          formData.training_days || "N/A",
          formData.training_amount || "N/A",
          travelTotal.toFixed(2),
        ],
        [
          "2",
          "Travel",
          formData.travel_expenses || "N/A",
          formData.travel_days || "N/A",
          formData.travel_amount || "N/A",
          travelTotal.toFixed(2),
        ],
        [
          "3",
          "Food",
          formData.food_allowance || "N/A",
          formData.food_days || "N/A",
          formData.food_amount || "N/A",
          foodTotal.toFixed(2),
        ],
        [
          "4",
          "Print",
          formData.misc_expenses || "N/A",
          formData.print_days || "N/A",
          formData.print_amount || "N/A",
          printTotal.toFixed(2),
        ],
      ],
      theme: "grid", // Use grid theme to have borders
      styles: {
        fontSize: 14, // Increase font size for the table content
      },
      headStyles: {
        fillColor: [211, 211, 211], // Set the header background color to light grey (RGB)
        textColor: [0, 0, 0], // Text color for the header
        fontSize: 14, // Increase font size for the header
        fontStyle: "bold", // Set font to bold for header
      },
      bodyStyles: {
        fontSize: 12, // Font size for table body
      },
    });

    // Add totals to the right of the table
    const finalY = doc.lastAutoTable.finalY + 10;
    const rightMargin = 140; // Set a right margin
    doc.text(`Gross: ${totalAmount.toFixed(2)}`, rightMargin, finalY);
    doc.text(
      `TDS Deduction (10%): ${tdsAmount.toFixed(2)}`,
      rightMargin,
      finalY + 10
    );
    doc.text(`Net Amount: ${netAmount.toFixed(2)}`, rightMargin, finalY + 20);

    // Save the PDF
    doc.save("invoice.pdf");
  };*/

  return (
    <div className="form-ques-invoice">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <strong className="form-in">Invoice No:</strong>
            <p></p>
            <div>
              <input
                className="invoice-input"
                name="invoice_no"
                placeholder="Invoice No"
                value={formData.invoice_no}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                }}
              />
            </div>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <p></p>
        <Row md={12}>
          <Col className="flex">
            <div className="add-profile-train" controlId="college_name">
              <label className="label6-ques">College Name**</label>
              <p></p>
              <Select
                
                className="clg-invoice" // Use class for width control
                options={college}
                value={selectedCollege}
                onChange={setSelectedCollege}
                placeholder="Select College"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: "#39444e", // Dark background
                    color: "white", // Text color
                    border: "1px solid white", // White border
                    boxShadow: "none", // Remove shadow
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: "white", // Color for the selected value
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: "#39444e", // Background color for dropdown menu
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isSelected ? "#2e3a44" : "#39444e", // Highlight selected option
                    color: "white", // Text color for options
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: "white", // Placeholder text color
                  }),
                  dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    color: "white", // Dropdown arrow color
                  }),
                }}
              />
            </div>
          </Col>

          <Col className="flex">
            <div className="date-trainer">
              <label className="label5-ques">Start Date:</label>
              <p></p>
              <DatePicker
                name="dtm_start"
                selected={startDateTime}
                onChange={(date) => setStartDateTime(date)}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={15}
                dateFormat="dd-MM-yyyy, h:mm aa"
                timeCaption="Time"
                className="input-date-custom-invoice"
                // styles={customStyles}
                autoComplete="off"
                required
              />
            </div>
          </Col>
          <Col className="flex">
            <div className="date-trainer">
              <label className="label5-ques">End Date:</label>
              <p></p>
              <DatePicker
                name="dtm_end"
                selected={endDateTime}
                onChange={(date) => setEndDateTime(date)}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={15}
                dateFormat="dd-MM-yyyy, h:mm aa"
                timeCaption="Time"
                className="input-date-custom-invoice"
                // styles={customStyles}
                autoComplete="off"
                required
              />
            </div>
          </Col>
        </Row>
        <p></p>
        <Row md={12}>
          <Col>
            <strong className="form-in">Misc Expenses:</strong>
            <p></p>
            <div
              className="invoice-input-drp"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid white",
                borderRadius: "5px",
                padding: "10px",
                backgroundColor: "#39444e",
                width: "70%",
                height: "80%",
              }}
            >
              <FontAwesomeIcon
                icon={faUpload}
                onClick={handleUploadClick}
                // onClick={() =>
                //   document.querySelector('input[name="misc_expenses"]').click()
                // }
                style={{
                  color: "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              />

              <input
                type="file"
                name="miscExpenses"
                onChange={handleFileChange}
                ref={miscExpensesInputRef}
                style={{ display: "none" }}
              />
              {/* Display file name */}
              <span style={{ color: "white", marginRight: "10px" }}>
                {fileNames.miscExpenses || "No file selected"}
              </span>
              {/* Custom styled dropdown */}
              <div style={{ position: "relative", flexGrow: 1 }}>
                <select
                  id="misc_expenses_type"
                  name="misc_expenses_type"
                  value={formData.misc_expenses_type}
                  onChange={handleInputChange}
                  required
                  style={{
                    appearance: "none",
                    width: "100%",
                    backgroundColor: "#39444e",
                    color: "white",
                    padding: "5px 40px 5px 10px",
                    border: "none",
                    textAlign: "center",
                    outline: "none",
                  }}
                >
                  <option value="">Misc Expenses</option>
                  <option value="Auto">Auto</option>
                  <option value="Courier">Courier</option>
                  <option value="Print">Print</option>
                  <option value="Others">Others</option>
                </select>

                {/* Dropdown icon */}
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    borderTop: "6px solid white",
                    borderLeft: "5px solid transparent",
                    borderRight: "5px solid transparent",
                  }}
                ></span>
              </div>
            </div>
          </Col>

          <Col>
            <strong className="form-in">Travel Expenses:</strong>
            <p></p>
            <div
              className="invoice-input-drp"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid white",
                borderRadius: "5px",
                padding: "10px",
                backgroundColor: "#39444e",
                width: "70%",
                height: "80%",
              }}
            >
              <FontAwesomeIcon
                icon={faUpload}
                onClick={handleTravelExpensesUploadClick}
                style={{
                  color: "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              />
              <input
                type="file"
                name="travelExpenses"
                onChange={handleFileChange}
                ref={travelExpensesInputRef}
                style={{ display: "none" }}
              />
              <span style={{ color: "white", marginRight: "10px" }}>
                {fileNames.travelExpenses || "No file selected"}
              </span>
            </div>
          </Col>

          <Col>
            <strong className="form-in">Food Allowance:</strong>
            <p></p>
            <div
              className="invoice-input-drp"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid white",
                borderRadius: "5px",
                padding: "10px",
                backgroundColor: "#39444e",
                width: "70%",
                height: "80%",
              }}
            >
              <FontAwesomeIcon
                icon={faUpload}
                onClick={handleFoodAllowanceUploadClick}
                style={{
                  color: "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              />
              <input
                type="file"
                name="foodAllowance"
                onChange={handleFileChange}
                ref={foodAllowanceInputRef}
                style={{ display: "none" }}
              />
              <span style={{ color: "white", marginRight: "10px" }}>
                {fileNames.foodAllowance || "No file selected"}
              </span>
            </div>
          </Col>
        </Row>
        <p></p>

        <Row style={{ gap: "20px" }}>
          {" "}
          <Col className="column">
            <strong className="form-in">Travel Expenses:</strong>
            <p></p>
            <div>
              <input
                className="invoice-input"
                name="travel_amount"
                placeholder="Travel Amount"
                value={formData.travel_amount}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px", // Even height for all inputs
                  borderRadius: "5px", // Border radius for uniform appearance
                }}
              />
            </div>
            {errors.travelExpenses && <span style={{ color: ' #F1A128' }}>{errors.travelExpenses}</span>}

          </Col>
          <Col>
            <strong className="form-in">Print/Xerox:</strong>
            <p></p>
            <div>
              <input
                className="invoice-input"
                name="print_amount"
                placeholder="Print Amount"
                value={formData.print_amount}
                onChange={handleInputChange}
                
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  marginLeft: "-2px"
                }}
              />

            </div>
            {errors.miscExpenses && <span style={{ color: ' #F1A128' }}>{errors.miscExpenses}</span>}

          </Col>
          <Col>
            <strong className="form-in">Food Expenses:</strong>
            <p></p>
            <div>
              <input
                className="invoice-input"
                name="food_amount"
                placeholder="Food Amount"
                value={formData.food_amount}
                onChange={handleInputChange}
                
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  marginLeft: "-7px"
                }}
              />

            </div>
            {errors.foodAllowance && <span style={{ color: ' #F1A128' }}>{errors.foodAllowance}</span>}

          </Col>
        </Row>

        <Row style={{ gap: "20px", marginTop: "20px" }}>
          <Col>
            <strong className="form-in">Travel Days:</strong>
            <p></p>
            <div>
              <textarea
                className="invoice-input"
                name="travel_days"
                placeholder="Travel Days"
                value={formData.travel_days}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  resize: "none",
                }}
              />
            </div>
          </Col>
          <Col>
            <strong className="form-in">Print Days:</strong>
            <p></p>
            <div>
              <textarea
                className="invoice-input"
                name="print_days"
                placeholder="Print Days"
                value={formData.print_days}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  resize: "none",
                  marginLeft: "-7px"
                }}
              />
            </div>
          </Col>
          <Col>
            <strong className="form-in">Food Days:</strong>
            <p></p>
            <div>
              <textarea
                className="invoice-input"
                name="food_days"
                placeholder="Food Days"
                value={formData.food_days}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  resize: "none",
                  marginLeft: "-7px"
                }}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ gap: "20px", marginTop: "20px" }}>
          <Col>
            <strong className="form-in">Training Amount:</strong>
            <p></p>
            <div>
              <input
                className="invoice-input"
                name="training_amount"
                placeholder="Training Amount"
                value={formData.training_amount}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                }}
              />
            </div>
          </Col>
          <Col>
            <strong className="form-in">Training Days:</strong>
            <p></p>
            <div>
              <textarea
                className="invoice-input"
                name="training_days"
                placeholder="Training Days"
                value={formData.training_days}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  resize: "none",
                  marginLeft: "-7px"
                }}
              />
            </div>
          </Col>
          <Col>
            <strong className="form-in">Overall Feedback:</strong>
            <p></p>
            <div>
              <textarea
                className="invoice-input"
                name="overall_feedback"
                placeholder="Overall Feedback"
                value={formData.overall_feedback}
                onChange={handleInputChange}
                required
                style={{
                  backgroundColor: "#39444e",
                  border: "1px solid white",
                  color: "white",
                  padding: "10px",
                  width: "73%",
                  height: "46px",
                  borderRadius: "5px",
                  resize: "none",
                  marginLeft: "-7px"
                }}
              />
            </div>
          </Col>
        </Row>
        <p></p>
        {invoiceDetails && (
          <Row style={{ gap: "20px", marginTop: "20px" }}>
            <Col>
              <strong className="form-in">Account No:</strong>
              <p></p>
              <div>
                <input
                  className="invoice-input"
                  name="account_no"
                  placeholder="Account No"
                  value={invoiceDetails.account_no || ""}
                  readOnly
                  style={{
                    backgroundColor: "#39444e",
                    border: "1px solid white",
                    color: "white",
                    padding: "10px",
                    width: "73%",
                    height: "46px",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </Col>
            <Col>
              <strong className="form-in">Address:</strong>
              <p></p>
              <div>
                <input
                  className="invoice-input"
                  name="address"
                  placeholder="Address"
                  value={invoiceDetails.address || ""}
                  readOnly
                  style={{
                    backgroundColor: "#39444e",
                    border: "1px solid white",
                    color: "white",
                    padding: "10px",
                    width: "73%",
                    height: "46px",
                    borderRadius: "5px",
                    marginLeft: "-7px"
                  }}
                />
              </div>
            </Col>
            <Col>
              <strong className="form-in">PAN Number:</strong>
              <p></p>
              <div>
                <input
                  className="invoice-input"
                  name="pan_number"
                  placeholder="PAN Number"
                  value={invoiceDetails.pan_number || ""}
                  readOnly
                  style={{
                    backgroundColor: "#39444e",
                    border: "1px solid white",
                    color: "white",
                    padding: "10px",
                    width: "73%",
                    height: "46px",
                    borderRadius: "5px",
                    marginLeft: "-7px"
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row
          className="btns-invoice"
          style={{ gap: "20px", marginTop: "20px" }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              type="button"
              className="button-invoice"
              onClick={handlePreview}
              style={{
                backgroundColor: "#f1a128", // Yellow background for button
                border: "1px solid black",
                color: "black",
                padding: "10px",
                width: "10%",
                height: "46px",
                borderRadius: "5px",
              }}
            >
              Preview
            </button>


            <button
              type="submit"
              disabled={isSubmitting}
              className="button-invoice-save"
              style={{
                backgroundColor: "#f1a128", // Yellow background for button
                border: "1px solid black",
                color: "black",
                padding: "10px",
                width: "10%",
                height: "46px",
                borderRadius: "5px",
              }}
            >
              Submit
            </button>
          </Col>
        </Row>
      </form>
      <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />

    </div>
  );
}

export default InvoiceForm;
