import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Sidebar from "./trainer/Sidebar";
import LearningMaterial from "./trainer/LMS/LearningMaterial";
import Header from "./Header/Header";
import { CssBaseline } from "@mui/material";
import ThemeContextProvider from "./ThemeContext";
import "./App.css";
import { SearchProvider } from "./AllSearch/SearchContext";
import TestReport from "./trainer/Test/TestReport";
import TrainerProfile from "./trainer/Database/TrainerProfile";
import InstructionPopup from "./trainer/Database/InstructionPopup"; // Import the popup component
import Update_MCQForm from './trainer/Questions/Update_MCQForm';
import Update_CodeForm from './trainer/Questions/Update_CodeForm';
import QuesPaperTb from "./trainer/Questions/QuesPaperTb";
import TrainerDashboard from "./trainer/Dashboard/TrainerDashboard";
import InvoiceForm from "./trainer/Invoice/Invoice";
import { Get_Instruction_popup_API, Update_Instruction_popup_API } from "./api/endpoints";

const Trainer = ({ username, institute, userRole }) => {
  const [theme, setTheme] = useState("black");
  const [accepted, setAccepted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    Get_Instruction_popup_API(username)
      .then((data) => {
        setOpenPopup(data.trainer_popup);
        console.log('Trainer popup state: ', data.trainer_popup);
        if (data.trainer_popup === false) {
          setAccepted(true);
          console.log('if accept is working..');
        }
      })
      .catch((error) => console.error('Error fetching trainer popup:', error));
  }, [username]); // Add `username` as a dependency
  

  const handleAccept = () => {
    Update_Instruction_popup_API(username)
      .then((data) => {
        console.log('Update Response:', data);
        setAccepted(true);
        setOpenPopup(false);
      })
      .catch((error) => {
        console.error('Error updating instruction popup:', error);
      });
  };
  

  const handleDecline = () => {
    // Redirect to an external URL
    window.location.href = "https://ccportal.co.in/";
  };

  return (
    <div className="App-header">
      <Router>
        {openPopup && (
          <InstructionPopup
            open={openPopup}
            handleAccept={handleAccept}
            handleDecline={handleDecline}
            username={username}
          />
        )}
        {accepted ? (
          <div className={`app ${theme}`}>
            <SearchProvider>
              <div className="content-wrapper">
                <ThemeContextProvider>
                  <CssBaseline />
                  <Header
                    theme={theme}
                    toggleTheme={toggleTheme}
                    username={username}
                    userRole={userRole}
                  />
                  <Sidebar />
                  <main
                    className="main-content"
                    style={{ marginLeft: "232px", marginTop: "60px" }}
                  >
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <TrainerDashboard
                            username={username}
                            institute={institute}
                          />
                        }
                      />
                      <Route
                        path="/lms/upload-video"
                        element={<LearningMaterial username={username} />}
                      />
                      <Route path="/test/report" element={<TestReport />} />
                      {/* <Route
                        path="/questions/mcq"
                        element={<QuestionPaperMCQ username={username} />}
                      /> */}
                      {/* <Route
                        path="/questions/coding"
                        element={<QuestionPaperCode />}
                      /> */}
                      <Route
                        path="/questions/question-paper"
                        element={
                          <QuesPaperTb
                            username={username}
                            userRole={userRole}
                          />
                        }
                      />
                    <Route
                        path="/update-mcq-form/:id"
                        element={<Update_MCQForm />}
                      />
                      <Route
                        path="/update-code-form/:id"
                        element={<Update_CodeForm />}
                      /> 

                      <Route
                        path="/database/upload-profile"
                        element={
                          <TrainerProfile
                            username={username}
                            userRole={userRole}
                          />
                        }
                      />
                      <Route
                        path="/invoice"
                        element={<InvoiceForm username={username} />}
                      />
                    </Routes>
                  </main>
                </ThemeContextProvider>
              </div>
            </SearchProvider>
          </div>
        ) : // Navigate to external URL handled by handleDecline function
        null}
      </Router>
    </div>
  );
};

export default Trainer;
