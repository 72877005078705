import React, { useEffect, useRef, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import {
  faBell,
  faMagnifyingGlass,
  faArrowRightLong,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { Line, Bar, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "./Dashboard.css";
import Footer from "../../Footer/Footer";
import {
  getTotalTestCount,
  getAvgScoreByDepartment,
  getAvgScoreByDepartmentCoding,
  CC_Attendance_API,
  getMaxScoreByDepartment,
  getMaxScoreByDepartmentCoding,
  getTotalCompanyCount,
  upcommingInterviewApi,
  interviewStatusCountApi,
  interviewResultStudntApi,
  interviewResultStudntEmailAddressApi,
  getcollegeApi,
  getOffer_College_id_API,
  getTotalOffers_college_id_API,
  getRequestCount_college_id_API,
  getAnnouncementMasterApi,
  getdepartmentApi,
  getcompanyApi,
  getAptitudeTotalCount_API,
  getTechnicalTotalCount_API,
  getRequestCount_CC_API,
  getAvgAptitude_cc_API,
  getAvgCoding_cc_API,
  getClgTopper_MCQ_CC_API,
  getClgTopper_Coding_CC_API,
  getTrainerDetails_cc_API,
  getTestDetails_cc_API,
  getNewUpdates_cc_API,
  getOfferChart_cc_API,
  getUpcomingInterview_cc_API,
  getOfferStatus_cc_API,
  getUniqueCmpy_cc_API,
  getunique_company_countApi,
  getUniqueCmpy_Count_cc_API,
  getClgRegistered_API,
  getJobOffer_Count_CC_API,
} from "../../api/endpoints";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { Autocomplete, TextField } from "@mui/material";
Chart.register(...registerables);

const Dashboard = () => {
  const [upcoming, setUpcoming] = useState([]); /*changes */
  const [totalTestCount, setTotalTestCount] = useState(null);
  const [totalComapnyCount, setTotalCompanyCount] = useState(null);
  const [collegeId, setCollegeID] = useState("");
  const [selectedOption, setSelectedOption] = useState("Aptitude");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [avgAptitudeScore, setAvgAptitudeScore] = useState([]);
  const [avgCodingScore, setAvgCodingScore] = useState([]);
  const [clgTopper, setClgTopper] = useState([]);
  const [selectedOptionClgTop, setSelectedOptionClgTop] = useState("MCQ");
  const [clgTopperCode, setClgTopperCode] = useState([]);
  const [upcomming, setUpcomming] = useState([]);
  const [offerStatus, setOfferStatus] = useState([]);
  const [offerPackage, setOfferPackage] = useState([]);
  const [studentCmpyDet, setStudentCmpyDet] = useState([]);
  const [college, setCollege] = useState([]);
  const [assessmentData1, setAssessmentData1] = useState([]);
  const [totalOffers, setTotalOffers] = useState(null);
  const [totalRequest, setTotalRequest] = useState(null);
  const [department, setDepartment] = useState([]);
  const [deptID, setDeptID] = useState("");
  const [companies, setCompanies] = useState([]);
  const [cmpyID, setCmpyID] = useState("");
  const [totalPresent, setTotalPresent] = useState("");
  const [totalAbsent, setTotalAbsent] = useState("");

  const [totalAptitudeCount, setTotalAptitudeCount] = useState(null);
  const [totalTechnicalCount, setTotalTechicalCount] = useState(null);
  const [trainerDetails, setTrainerDetails] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [announcements, setAnnouncements] = useState([]); // Define announcements state
  const [role, setRole] = useState("Training admin");
  const [Registered, setRegistered] = useState(0);
  const [totOffers, setTotOffers] = useState(0);
  const [currentIndexPlace, setCurrentIndexPlace] = useState(0);
  const [announcementsPlace, setAnnouncementsPlace] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [clgAttend, setClgAttend] = useState("");
  const [attendanceURL, setAttendanceURL] = useState("");
  const [chartType, setChartType] = useState("Line");

  const fetchAnnouncementsPlace = () => {
    getNewUpdates_cc_API(role, collegeId)
      .then((data) => {
        setAnnouncementsPlace(data);
      })
      .catch((error) => console.error("Error fetching announcements:", error));
  };

  // Handler for previous announcement
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handler for next announcement
  const handleNext = () => {
    if (currentIndex < announcements.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handler for previous announcement
  const handlePreviousPlace = () => {
    if (currentIndexPlace > 0) {
      setCurrentIndexPlace(currentIndexPlace - 1);
    }
  };

  // Handler for next announcement
  const handleNextPlace = () => {
    if (currentIndexPlace < announcements.length - 1) {
      setCurrentIndexPlace(currentIndexPlace + 1);
    }
  };

  useEffect(() => {
    if (role === "Training admin") {
      fetchAttendance();
      fetchCollege();
      fetchAptitudeCount();
      fetchTechnicalCount();
      fetchTotalRequestCount();
      aptitudeAvgScore();
      getCodingAvgCore();
      getCollegeTopper();
      getCollegeTopperCoding();
      getTrainerDetails();
      getTestDetails();
      fetchAnnouncements();
    } else if (role === "Placement admin") {
      fetchCompanyuniqueCount();
      fetchcollegeRegisteredCount();
      fetchcollegeOffer();
      fetchTotalRequestCount();
      fetchOfferChart();
      fetchDepartment();
      getUpcomming();
      fetchCompanies();
      getOfferStatus();
      fetchAnnouncementsPlace();
    }
  }, [role, collegeId, selectedDate, deptID, cmpyID]);

  const fetchCollege = async () => {
    try {
      const response = await getcollegeApi(); // Await the promise
      console.log("College: ", response);
      setCollege(response); // Access data directly
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchAttendance = async () => {
    try {
      const response = await CC_Attendance_API(collegeId);

      // Process the attendance data
      console.log("Total Present count: ", response.Total_Present);
      console.log("Total Absent count: ", response.Total_Absent);
      console.log("college Attend: ", response.college);
      console.log("Attendance_URL: ", response.Attendance_URL);
      setTotalPresent(response.Total_Present); // Update state for present count
      setTotalAbsent(response.Total_Absent);
      setClgAttend(response.college);
      setAttendanceURL(response.Attendance_URL);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchDepartment = async () => {
    try {
      const response = await getdepartmentApi(); // Await the promise
      console.log("Departments: ", response);
      setDepartment(response); // Access data directly
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await getUniqueCmpy_cc_API(); // Await the promise
      console.log("Companies: ", response);
      setCompanies(response); // Access data directly
    } catch (err) {
      console.log(err.message);
    }
  };

  //-----------------Total Aptitude, Technical Count------------------------//

  const fetchAptitudeCount = async () => {
    try {
      const response = await getAptitudeTotalCount_API(collegeId);
      console.log("total Aptitude count: ", response);
      setTotalAptitudeCount(response.count);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchTechnicalCount = async () => {
    try {
      const response = await getTechnicalTotalCount_API(collegeId); // Await the promise
      console.log("response of total technical count: ", response);
      setTotalTechicalCount(response.count); // Access data directly
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchTotalRequestCount = async () => {
    try {
      const response = await getRequestCount_CC_API(collegeId, role); // Await the promise
      console.log("totalRequest count: ", response);
      setTotalRequest(response.request_count); // Access data directly
    } catch (err) {
      console.log(err.message);
    }
  };

  const aptitudeAvgScore = async () => {
    try {
      const response = await getAvgAptitude_cc_API(collegeId, selectedDate);
      console.log("Avg score of Department: ", response);
      setAvgAptitudeScore(response);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCodingAvgCore = async () => {
    try {
      console.log("coding avg entering........");
      const response = await getAvgCoding_cc_API(collegeId, selectedDate);
      console.log("Coding Avg score of Department: ", response);
      setAvgCodingScore(response);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCollegeTopper = () => {
    getClgTopper_MCQ_CC_API(collegeId)
      .then((data) => {
        setClgTopper(data);
      })
      .catch((error) =>
        console.error("Error fetching getting College Topper:", error)
      );
  };

  const getCollegeTopperCoding = () => {
    getClgTopper_Coding_CC_API(collegeId)
      .then((data) => {
        setClgTopperCode(data);
      })
      .catch((error) =>
        console.error("Error fetching getting College Topper:", error)
      );
  };

  const getTrainerDetails = () => {
    getTrainerDetails_cc_API(collegeId)
      .then((data) => {
        setTrainerDetails(data);
      })
      .catch((error) =>
        console.error("Error fetching getting upcomming interview:", error)
      );
  };

  const getTestDetails = () => {
    getTestDetails_cc_API(collegeId)
      .then((data) => {
        setTestDetails(data);
      })
      .catch((error) =>
        console.error("Error fetching getting upcomming interview:", error)
      );
  };

  const fetchAnnouncements = () => {
    getNewUpdates_cc_API(role, collegeId)
      .then((data) => {
        setAnnouncements(data);
      })
      .catch((error) => console.error("Error fetching announcements:", error));
  };

  const fetchOfferChart = () => {
    getOfferChart_cc_API(collegeId)
      .then((data) => {
        console.log("response", data);

        const totalOffers = data.reduce(
          (acc, item) => acc + item.number_of_offers,
          0
        );
        const itOffers = data.reduce((acc, item) => acc + item.it_of_offers, 0);
        const coreOffers = data.reduce(
          (acc, item) => acc + item.core_of_offers,
          0
        );
        const otherCount = data.filter(
          (item) => item.number_of_offers === 0
        ).length;

        console.log("total", totalOffers);
        console.log("it", itOffers);
        console.log("core", coreOffers);
        console.log("others count", otherCount);

        const assessmentData = [
          {
            type: "IT Offers",
            percentage: Number(((itOffers / totalOffers) * 100).toFixed(2)),
            color: "blue",
          },
          {
            type: "Core Offers",
            percentage: Number(((coreOffers / totalOffers) * 100).toFixed(2)),
            color: "green",
          },
        ];

        if (otherCount > 0) {
          assessmentData.push({
            type: "Others",
            percentage: Number(
              ((otherCount / (totalOffers + otherCount)) * 100).toFixed(2)
            ),
            color: "grey",
          });
        }

        setAssessmentData1(assessmentData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getUpcomming = () => {
    getUpcomingInterview_cc_API(collegeId, deptID)
      .then((data) => {
        setUpcomming(data);
        console.log("upcomming interview: ", data);
      })
      .catch((error) =>
        console.error("Error fetching getting upcomming interview:", error)
      );
  };

  const getOfferStatus = () => {
    getOfferStatus_cc_API(collegeId, cmpyID)
      .then((data) => {
        setOfferStatus(data);
        console.log("offer Status: ", data);
      })
      .catch((error) =>
        console.error("Error fetching getting upcomming interview:", error)
      );
  };

  const fetchCompanyuniqueCount = async () => {
    try {
      const response = await getUniqueCmpy_Count_cc_API(collegeId); // Await the promise
      console.log("response of total test count: ", response);
      setTotalCompanyCount(response.unique_company_count);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchcollegeRegisteredCount = async () => {
    try {
      const response = await getClgRegistered_API(collegeId); // Await the promise
      console.log("response of college registered count: ", response);
      setRegistered(response.college_accepted_count);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchcollegeOffer = async () => {
    try {
      const response = await getJobOffer_Count_CC_API(collegeId); // Await the promise
      console.log("response of total test count: ", response);
      setTotOffers(response.job_offer_count); // Access data directly
    } catch (err) {
      console.log(err.message);
    }
  };

  //-------------------------------------------------------------------//

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleColor: "#ACBFD2",
        bodyColor: "#ACBFD2",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#ACBFD2",
        },
        title: {
          display: true,
          color: "#ACBFD2",
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          color: "#ACBFD2",
          stepSize: 10,
          callback: function (value) {
            if (value % 10 === 0) {
              return value;
            }
          },
        },
        title: {
          display: true,
          color: "#ACBFD2",
        },
      },
    },
  };

  const pieChartRef = useRef(null);

  useEffect(() => {
    if (pieChartRef.current) {
      const pieChart = new Chart(pieChartRef.current, {
        type: "doughnut",
        data: {
          labels: assessmentData1.map((data) => data.type),
          datasets: [
            {
              data: assessmentData1.map((data) => data.percentage),
              backgroundColor: assessmentData1.map((data) => data.color),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              labels: {
                color: "rgb(157, 190, 210", // Set legend text color to rgb(157, 190, 210
              },
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) =>
                  `${tooltipItem.label}: ${tooltipItem.raw}%`,
              },
            },
          },
          cutout: "50%",
        },
      });
      return () => {
        pieChart.destroy();
      };
    }
  }, [assessmentData1]);

  const handleCardClick = () => {
    if (clgAttend === "separated" && attendanceURL) {
      window.open(attendanceURL, "_blank");
    }
  };

  // dropdown

  const collegeOptions = [
    { label: "All", value: "" },
    ...college.map((item) => ({
      label: item.college,
      value: item.id,
    })),
  ];

  const chartDataAvgAptitude = {
    labels: avgAptitudeScore.map((data) => data.department_name),
    datasets: [
      {
        data: avgAptitudeScore.map((data) => data.avg_score || 0),
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.4)",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const renderChartAvgAptitudeScore = () => {
    if (chartType === "Pie") {
      return (
        <div
          style={{
            width: "220px", // Set fixed width
            height: "220px", // Set fixed height
            margin: "0 auto", // Center the chart horizontally
          }}
        >
          <Pie
            data={{
              labels: avgAptitudeScore.map((data) => data.department_name),
              datasets: [
                {
                  data: avgAptitudeScore.map((data) => data.avg_score || 0),
                  backgroundColor: [
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                    "rgba(255, 159, 64, 0.6)",
                  ],
                  borderColor: [
                    "rgba(75, 192, 192, 1)",
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top", // Position legend on the right
                  labels: {
                    usePointStyle: true, // Use a bullet point instead of a box
                    pointStyle: "circle", // Style the bullet as a circle
                    boxWidth: 5, // Decrease the size of the point/bullet
                    font: {
                      size: 8, // Decrease the font size of the labels
                    },
                    color: "white",
                    padding: 10, // Add padding between the labels
                  },
                },
                tooltip: {
                  enabled: true, // Enable tooltips for interactivity
                },
              },
              layout: {
                padding: {
                  left: 10, // Add some padding to avoid crowding
                  right: 10,
                },
              },
              scales: {
                x: {
                  display: false, // Disable the x-axis
                },
                y: {
                  display: false, // Disable the y-axis
                },
              },
            }}
          />
        </div>
      );
    } else if (chartType === "Bar") {
      return <Bar data={chartDataAvgAptitude} options={chartOptions} />;
    } else {
      return <Line data={chartDataAvgAptitude} options={chartOptions} />;
    }
  };

  const chartDataAvgCoding = {
    labels: avgCodingScore.map((data) => data.department_name),
    datasets: [
      {
        data: avgCodingScore.map((data) => data.avg_score || 0),
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.4)",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const renderChartAvgCodingScore = () => {
    if (chartType === "Pie") {
      return (
        <div
          style={{
            width: "220px", // Set fixed width
            height: "220px", // Set fixed height
            margin: "0 auto", // Center the chart horizontally
          }}
        >
          <Pie
            data={{
              labels: avgCodingScore.map((data) => data.department_name),
              datasets: [
                {
                  data: avgCodingScore.map((data) => data.avg_score || 0),
                  backgroundColor: [
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                    "rgba(255, 159, 64, 0.6)",
                  ],
                  borderColor: [
                    "rgba(75, 192, 192, 1)",
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top", // Position legend on the right
                  labels: {
                    usePointStyle: true, // Use a bullet point instead of a box
                    pointStyle: "circle", // Style the bullet as a circle
                    boxWidth: 5, // Decrease the size of the point/bullet
                    font: {
                      size: 8, // Decrease the font size of the labels
                    },
                    color: "white",
                    padding: 10, // Add padding between the labels
                  },
                },
                tooltip: {
                  enabled: true, // Enable tooltips for interactivity
                },
              },
              layout: {
                padding: {
                  left: 10, // Add some padding to avoid crowding
                  right: 10,
                },
              },
              scales: {
                x: {
                  display: false, // Disable the x-axis
                },
                y: {
                  display: false, // Disable the y-axis
                },
              },
            }}
          />
        </div>
      );
    } else if (chartType === "Bar") {
      return <Bar data={chartDataAvgCoding} options={chartOptions} />;
    } else {
      return <Line data={chartDataAvgCoding} options={chartOptions} />;
    }
  };

  return (
    <div
      className="App-training"
      style={{ backgroundColor: "#323B44", color: "rgb(157, 190, 210)f" }}
    >
      <div
        className="select-training-drp"
        style={{ display: "flex", marginBottom: "20px" }}
      >
        {/* College Dropdown */}
        <Autocomplete
          disableClearable
          value={
            collegeId === ""
              ? { label: "All", value: "" } // Default to "All" if no college is selected
              : collegeOptions.find((option) => option.value === collegeId) ||
                null
          }
          onChange={(event, newValue) =>
            setCollegeID(newValue ? newValue.value : "")
          }
          options={collegeOptions}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          PaperComponent={({ children }) => (
            <div
              style={{
                backgroundColor: "#3e4954",
                color: "rgb(157, 190, 210)",
                borderRadius: "20px",
                border: "black",
              }}
            >
              {children}
            </div>
          )}
          sx={{
            width: "35%",
            marginLeft: "30%",
            marginTop: "20px",
            "& .MuiOutlinedInput-root": {
              color: "rgb(157, 190, 210)",
              fontWeight: "bold",
              backgroundColor: "#3e4954",
              borderRadius: "40px",
              padding: "10px",
              "& fieldset": {
                border: "10px solid #18c4bb", // Initial border color
              },
              "&:hover fieldset": {
                border: "10px solid #18c4bb", // Keep the border color unchanged on hover
              },
              "& .MuiSvgIcon-root": {
                color: "white", // Change the dropdown arrow color to white
              },
            },
            "& .MuiAutocomplete-option:hover": {
              backgroundColor: "#4a545f !important",
            },
            "& .MuiAutocomplete-paper": {
              backgroundColor: "#3e4954", // Set dropdown options background
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                readOnly: true, // Makes the dropdown non-editable
              }}
            />
          )}
        />
      </div>

      <p></p>
      <div>
        <section className="card-list-train">
          <div className="training-admin">
            <div className="card-container-training">
              <div className="square-container-training">
                <div
                  className={`card-lms card-test-count ${
                    clgAttend === "separated" ? "clickable" : ""
                  }`}
                  onClick={clgAttend === "separated" ? handleCardClick : null}
                  style={clgAttend === "separated" ? { cursor: "pointer" } : {}}
                >
                  <div className="card-content-wrapper">
                    <p className="card-content-training">
                      {totalPresent ? totalPresent : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h6 className="card-title-training">Total Attendance</h6>
          </div>
          <p></p>

          <div className="training-admin">
            <div className="card-container-training">
              <div className="square-container-training">
                <div className="card-lms card-companies">
                  <div className="card-content-wrapper">
                    <p className="card-content-training">
                      {totalAptitudeCount ? totalAptitudeCount : 1}
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
            <h2 className="card-title-training">Total Aptitude Test</h2>
          </div>
          <p></p>

          <div className="training-admin">
            <div className="card-container-training">
              <div className="square-container-training">
                <div className="card-lms card-offers">
                  <div className="card-content-wrapper">
                    <p className="card-content-training">
                      {totalTechnicalCount ? totalTechnicalCount : 0}
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
            <h2 className="card-title-training">Total Technical Test</h2>
          </div>
          <p></p>

          <div className="training-admin-requests">
            <div className="card-container-training">
              <div className="square-container-training">
                <div className="card-lms card-requests">
                  <div className="card-content-wrapper">
                    <p className="card-content-requ">
                      {totalRequest ? totalRequest : 0}
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
            <h2 className="card-title-training">Requests</h2>
          </div>
          <Container className="container-dash-training">
            <Row className="my-row-training">
              <Col className="border p-3 department-attendance col-department-attendance">
                <div className="test test-report">
                  <h6 className="h6-bold-report" style={{ color: "white" }}>
                    TEST REPORT
                  </h6>
                  <div className="date-picker-wrapper">
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="dd-MMM-yyyy"
                      className="input-date-train"
                    />
                  </div>
                  {/* <div>
                    <select
                      value={chartType}
                      onChange={(e) => setChartType(e.target.value)}
                      className="college-topper-select"
                    >
                      <option value="Line">Line Chart</option>
                      <option value="Bar">Bar Chart</option>
                      <option value="Pie">Pie Chart</option>
                    </select>
                  </div> */}
                </div>

                <div className="score-charts">
                  <div className="chart-wrapper">
                    <p className="chart-title" style={{ color: "white" }}>
                      Avg Score-Aptitude
                    </p>
                    <div className="chart-container">
                      {renderChartAvgAptitudeScore()}
                    </div>
                  </div>

                  <div className="chart-wrapper">
                    <p className="chart-title" style={{ color: "white" }}>
                      Avg Score-Coding
                    </p>
                    <div className="chart-container">
                      {renderChartAvgCodingScore()}
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                style={{ maxWidth: "253px !important" }}
                className="border p-3 department-attendance col-department-attendance"
              >
                <div className="test test-college-topper">
                  <h6 className="h6-bold-topper" style={{ color: "white" }}>
                    COLLEGE_TOPPER
                  </h6>
                  <select
                    onChange={(e) => setSelectedOptionClgTop(e.target.value)}
                    value={selectedOptionClgTop}
                    className="college-topper-select"
                    style={{ color: "white" }}
                  >
                    <option value="MCQ">MCQ</option>
                    <option value="Coding">Coding</option>
                  </select>
                </div>

                {selectedOptionClgTop === "MCQ" && (
                  <table className="table college-topper-table">
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Name
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Department
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clgTopper.length === 0 ? (
                        <tr>
                          <td
                            colSpan={4} // Span all columns
                            style={{
                              color: "#ffff",
                              backgroundColor: "#3e4954",
                              textAlign: "center", // Center align text
                            }}
                            className="table-cell"
                          >
                            College Topper not found
                          </td>
                        </tr>
                      ) : (
                        clgTopper.map((item) => (
                          <tr key={item.id}>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.student_name}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.department}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.max_total_score}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}

                {selectedOptionClgTop === "Coding" && (
                  <table className="table college-topper-table">
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Name
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Department
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clgTopper.length === 0 ? (
                        <tr>
                          <td
                            colSpan={4} // Span all columns
                            style={{
                              color: "#ffff",
                              backgroundColor: "#3e4954",
                              textAlign: "center", // Center align text
                            }}
                            className="table-cell"
                          >
                            College Topper not found
                          </td>
                        </tr>
                      ) : (
                        clgTopperCode.map((item) => (
                          <tr key={item.id}>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.student_name}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.department}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.max_total_score}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}

                <div />
              </Col>
            </Row>
          </Container>

          <div className="container-details">
            <div className="row-table-training">
              <div className="col-dashboard">
                <div className="test2">
                  <h6 className="h6-bold" style={{ color: "white" }}>
                    Trainer Details
                  </h6>
                </div>
                <div className="table-responsive-dash-traidet">
                  <table
                    className={`table-trainer ${
                      trainerDetails.length > 5 ? "table-scrollable" : ""
                    }`}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Name
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Skills
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Topic
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Start Date
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          End Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainerDetails.length === 0 ? (
                        <tr>
                          <td
                            colSpan={4} // Span all columns
                            style={{
                              color: "#ffff",
                              backgroundColor: "#3e4954",
                              textAlign: "center", // Center align text
                            }}
                            className="table-cell"
                          >
                            Data not found
                          </td>
                        </tr>
                      ) : (
                        trainerDetails.map((item) => (
                          <tr key={item.id}>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.trainer_name}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.trainer_skills}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.topic}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.dtm_start_trainer}
                            </td>
                            <td
                              style={{
                                color: "white",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.dtm_end_trainer}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-dashboard">
                <div className="test2">
                  <h6 className="h6-bold" style={{ color: "white" }}>
                    Test Details
                  </h6>
                </div>
                <div className="table-responsive-dash">
                  <table
                    className={`table-test ${
                      trainerDetails.length > 5 ? "table-scrollable" : ""
                    }`}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          College
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Department
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Topic
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                          className="table-header"
                        >
                          Test Date
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "white",
                          }}
                          className="table-header"
                        >
                          Start Time
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#3e4954",
                            color: "#fff",
                          }}
                          className="table-header"
                        >
                          End Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testDetails.length === 0 ? (
                        <tr>
                          <td
                            colSpan={4} // Span all columns
                            style={{
                              color: "#ffff",
                              backgroundColor: "#3e4954",
                              textAlign: "center", // Center align text
                            }}
                            className="table-cell"
                          >
                            Data not found
                          </td>
                        </tr>
                      ) : (
                        testDetails.map((item) => (
                          <tr key={item.id}>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.college_name}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.departments}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.topic}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.test_start}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.start_time}
                            </td>
                            <td
                              style={{
                                color: "#fff",
                                backgroundColor: "#3e4954",
                              }}
                              className="table-cell"
                            >
                              {item.end_time}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="news-updates-training">
          <div className="news-updates-header">
            <h6 className="h6-bold-news-place">NEWS UPDATES</h6>
          </div>

          <div className="table-responsive-dash">
            {announcements.length > 0 ? (
              <div
                className={`table-test ${
                  announcements.length > 1 ? "table-scrollable" : ""
                }`}
              >
                {announcements.map((announcement, index) => (
                  <div key={index} className="announcement-item">
                    <p className="news-head-com">{announcement.announcement}</p>
                    {announcement.announcement_image && (
                      <img
                        className="news-img-com"
                        src={`data:image/png;base64,${announcement.announcement_image}`}
                        alt="Announcement"
                        width={80}
                        height={80}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p>No announcements available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
